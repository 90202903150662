import { addMonths, endOfMonth, getDate, setDate, subMonths } from 'date-fns';
import { Source } from '../../podio/config';

export function getPayPeriodOfDate(date: Date, source: Source | null): Interval | undefined {
  if (!source) {
    return;
  }

  if (source === 'Denmark') {
    if (getDate(date) <= 15) {
      return {
        start: setDate(subMonths(date, 1), 16),
        end: setDate(date, 15),
      };
    } else {
      return {
        start: setDate(date, 16),
        end: setDate(addMonths(date, 1), 15),
      };
    }
  }
  // Currently this else handles the Norway office source
  else {
    return { start: setDate(date, 1), end: endOfMonth(date) };
  }
}
