import { formatISO } from 'date-fns';
import { Source, sourceConfig } from '../config';
import { podioFetch } from '../core/podio-fetch';
import { Timesheet, User } from '../models';

interface PodioTimesheet {
  employee: [{ value: { type: string; id: number } }];
  'project-customer': [{ value: number }];
  task: [{ value: number }];
  date: { start_date: string };
  'time-spent': number;
  'details-of-work': string;
}

export async function addTimesheet(timesheet: Timesheet, userId: User['id'], source: Source) {
  const fields: PodioTimesheet = {
    date: { start_date: formatISO(timesheet.date, { representation: 'date' }) },
    'details-of-work': timesheet.detailsOfWork,
    employee: [
      {
        value: {
          id: userId,
          type: 'user',
        },
      },
    ],
    'project-customer': [{ value: timesheet.projectId }],
    task: [{ value: timesheet.taskId }],
    'time-spent': timesheet.timeSpent,
  };

  await podioFetch(`/item/app/${sourceConfig[source].timesheets}?hook=true`, {
    method: 'POST',
    data: JSON.stringify({ fields }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
