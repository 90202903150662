import Box from '@mui/material/Box';
import { TimesheetCard } from './TimesheetCard';
import React from 'react';
import { getTimeSpentPerTask } from '../lib/timesheets/get-time-spent-per-task';
import { Timesheet } from '../podio/models';
import { useSettings } from '../account/use-settings';
import { getIntervalInWeeks } from '../lib/timesheets/get-interval-in-weeks';
import { WeekCards } from './WeekCard';
import { WeekModeButton } from './WeekModeButton';

interface TaskViewerProps {
  timesheets: Timesheet[];
}

export function TaskViewer({ timesheets }: TaskViewerProps) {
  const { weekMode } = useSettings();
  const intervalInWeeksInDescendingOrder = getIntervalInWeeks(timesheets).reverse();

  const timeSpentPerTask = getTimeSpentPerTask(timesheets);

  return (
    <>
      <WeekModeButton />
      {/*<WorkingHours />*/}
      {weekMode
        ? intervalInWeeksInDescendingOrder.map((timesheets, week) => (
            <Box mb={2} key={week}>
              <WeekCards timesheets={timesheets} />
            </Box>
          ))
        : timeSpentPerTask.map((task) => <TimesheetCard timesheet={task} key={task.taskId} />)}
    </>
  );
}
