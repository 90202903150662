import axios, { AxiosRequestConfig } from 'axios';
import { refreshAccessToken } from '../../podio/auth/refresh-access-token';
import { podioApiOrigin } from '../../podio/config';
import { getAccessToken, setAccessToken } from '../auth/access-token';
import { getRefreshToken, setRefreshToken } from '../auth/refresh-token';

export const apiUrl = 'https://linux-timemindster-development.azurewebsites.net';

const backendApiPrefix = '/api/';

const requestInterceptor = (config: AxiosRequestConfig<any>) => {
  if (config.url?.startsWith(backendApiPrefix)) {
    config.url = `${process.env.REACT_APP_API_URL}${config.url}`;
  }

  if (config.url?.includes(backendApiPrefix)) {
    const user = JSON.parse(localStorage.getItem('tm-user') || '{}');
    return handleAddAuthOnApiRequest(config, user.id);
  }

  if (config.url?.includes(podioApiOrigin)) {
    return handleAddAuthOnApiRequest(config);
  }
  return config;
};

export const setRequestInterceptors = () => axios.interceptors.request.use(requestInterceptor, (error) => error);

export const setResponseInterceptors = () =>
  axios.interceptors.response.use(
    // We do not need to modify the success callback
    (config) => config,
    async function (error) {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      if (error.config.url.includes(podioApiOrigin) || error.config.url.includes(backendApiPrefix)) {
        return handleApiUnauthorizedError(error);
      } else {
        logout();
      }
    },
  );

function handleAddAuthOnApiRequest(config, podioId?) {
  const accessToken = getAccessToken();

  if (!accessToken) {
    logout();
    throw new Error('You are not logged in');
  }

  const auth = podioId ? `Bearer ${accessToken}.${podioId}` : `Bearer ${accessToken}`;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: auth,
    },
  };
}

async function fetchAndSetNewTokens() {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    logout();
    throw new Error('You are not logged in');
  }

  const refreshResponse = await refreshAccessToken(refreshToken);

  if (!refreshResponse.accessToken) {
    logout();
    throw new Error('Unable to get a new access token');
  }

  setAccessToken(refreshResponse.accessToken);
  setRefreshToken(refreshResponse.refreshToken);
}

async function handleApiUnauthorizedError(error) {
  await fetchAndSetNewTokens();

  const request = {
    ...error.config,
    headers: {
      ...error.config?.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return axios(request);
}

export function logout() {
  localStorage.clear();
  window.location.href = '/login';
}

export function logoutWithError(error: number) {
  localStorage.clear();
  window.location.href = `/login?error=${error}`;
}
