export function displayDuration(seconds: number) {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.round((seconds / 3600 - hours) * 60);

  return [hours, minutes];
}

export function displayDurationinDecimal(seconds: number) {
  const time: number = seconds / 3600;

  return time;
}
