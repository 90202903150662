import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  alpha,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowTopRight, Tree } from 'mdi-material-ui';
import { useState } from 'react';
import { DataCardProps } from '../lib/models/data-card-model';
import { FaqButton } from './faq-component';

export const DataCard = ({
  title,
  description,
  image,
  externalLink,
  amountPlanted,
  chips,
  allowCollapse,
  initialCollapse,
  handleMediaClick,
  handleImageError,
}: DataCardProps) => {
  const [expanded, setExpanded] = useState(!initialCollapse);

  const chipStyling: CSSProperties = {
    position: 'absolute',
    backgroundColor: alpha('#222', 0.6),
    color: '#fff',
    minWidth: 120,
    left: 12,
    visibility: expanded ? 'visible' : 'collapse',
    transition: 'visibility',
    transitionDelay: '0.11s',
  };

  const handleExpandClick = () => {
    if (!allowCollapse) return;
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ position: 'relative', my: 1 }}>
      <CardContent
        sx={{ '&&': { padding: '4px', paddingLeft: '10px' }, ...(allowCollapse && { cursor: 'pointer' }) }}
        onClick={handleExpandClick}
      >
        <Grid container flexGrow={1} alignItems="center" sx={{ minHeight: '32px' }}>
          <Grid item textAlign="left">
            <Typography fontWeight="500" paddingLeft={1}>
              {title}
            </Typography>
          </Grid>
          <Grid item marginLeft="auto">
            {externalLink && (
              <IconButton href={externalLink} rel="noopener noreferrer" target="_blank" sx={{ padding: '4px' }}>
                <ArrowTopRight fontSize="small" />
              </IconButton>
            )}
            {amountPlanted && (
              <Box sx={{ color: 'garden' }}>
                {amountPlanted}
                <Tree
                  sx={{
                    transform: 'translateY(4px)',
                  }}
                  fontSize="small"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded}>
        <CardActionArea onClick={handleMediaClick} disabled={!handleMediaClick}>
          <CardMedia component="img" height="180" image={image} alt={title} onError={handleImageError} />
        </CardActionArea>
        <FaqButton
          style={{
            bottom: 0,
            minWidth: '50px',
            maxWidth: '50px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 20,
            visibility: expanded ? 'visible' : 'collapse',
            transition: 'visibility',
            transitionDelay: '0.11s',
          }}
          title={title}
        >
          {description}
        </FaqButton>
        {chips?.map((chip, index) => (
          <Chip key={`${chip}_${index}`} style={chipStyling} sx={{ bottom: 12 + 43 * index }} label={chip} />
        ))}
      </Collapse>
    </Card>
  );
};
