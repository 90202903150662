import { alpha, Grid, Typography } from '@mui/material';

export interface Props {
  number: number | string;
  secondaryNumber?: number | string;
  accentColor: boolean;
  text: string;
  unit: string;
  secondaryUnit?: string;
  shortNotation: boolean;
}

export const KpiComponent = ({
  number,
  secondaryNumber,
  accentColor,
  text,
  unit,
  secondaryUnit,
  shortNotation,
}: Props) => {
  return (
    <Grid item textAlign="center">
      <Typography
        sx={{
          color: accentColor ? 'secondary.main' : '',
        }}
      >
        <span style={{ fontSize: 24, fontWeight: 'bold' }}>{number}</span>
        {unit}
        {shortNotation ? '' : number == 1 ? '' : 's'}
        {secondaryNumber && secondaryUnit ? (
          <span>
            {' '}
            <span style={{ fontSize: 24, fontWeight: 'bold' }}>{secondaryNumber}</span>
            {secondaryUnit}
            {shortNotation ? '' : number == 1 ? '' : 's'}{' '}
          </span>
        ) : (
          <></>
        )}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontWeight: 'light',
          color: alpha(theme.palette.text.primary, 0.7),
        })}
      >
        {text}
      </Typography>
    </Grid>
  );
};
