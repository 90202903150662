import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { displayDuration } from '../lib/time/display-duration';
import { Panel } from '../core/Panel';
import { alpha, Box, Grid, Theme, useTheme } from '@mui/material';
import { Timesheet } from '../podio/models';

type PartialTimesheetProps = {
  timesheet: Pick<Timesheet, 'taskId' | 'timeSpent' | 'projectTitle' | 'taskTitle'>;
  detailsOfWork?: string;
};

export const TimesheetCard: FC<PartialTimesheetProps> = ({ timesheet, detailsOfWork }) => {
  const [hours, minutes] = displayDuration(timesheet.timeSpent);
  const theme = useTheme<Theme>();
  return (
    <Box key={timesheet.taskId} my={1}>
      <Panel key={timesheet.taskId}>
        <Grid container alignItems="center">
          <Grid item>
            <DisplayTime hours={hours} minutes={minutes}></DisplayTime>
          </Grid>
          <Grid
            item
            xs={true}
            sx={{
              borderLeft: '1px solid',
              borderColor: theme.palette.secondary.main,
              pl: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{timesheet.projectTitle}</Typography>
            <Typography sx={{ color: alpha(theme.palette.text.primary, 0.7), fontSize: '14px' }}>
              {timesheet.taskTitle}
            </Typography>
            {detailsOfWork != null ? (
              <Typography
                sx={{
                  color: alpha(theme.palette.text.primary, 0.7),
                  my: '5px',
                  py: '4px',
                }}
                fontSize="14px"
                borderTop="1px solid"
                borderColor={alpha(theme.palette.text.primary, 0.3)}
              >
                {detailsOfWork}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Panel>
    </Box>
  );
};

interface PropsTime {
  hours: number;
  minutes: number;
}

const DisplayTime = ({ hours, minutes }: PropsTime) => {
  return (
    <Grid container direction="column" pr={2} minWidth={62}>
      <strong style={{ textAlign: 'right' }}>
        <Typography component="span" fontWeight="bold" fontSize="20px">
          {hours}
        </Typography>
        h
      </strong>
      <Typography component="span" fontWeight="bold" fontSize="15px" textAlign="right">
        {minutes}m
      </Typography>
    </Grid>
  );
};
