import { Box, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Lottie from 'react-lottie';
import animationData from '../garden/lotties/tree-notification-animation.json';

type Props = {
  progressLeft: number;
  selfCloseTime: number;
  open: boolean;
  onClose: () => void;
};

export const PopUpNotification = ({ progressLeft, selfCloseTime, open, onClose }: Props) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={selfCloseTime}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Paper>
          <Box p={2} display="flex" flexDirection="column" sx={{ maxWidth: 450 }}>
            <Box display="flex" flexDirection="row" sx={{ marginBottom: 1 }}>
              <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>Awesome! </Typography>
              <Box sx={{ marginLeft: 'auto' }}>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box
                style={{
                  width: '90vw',
                  maxWidth: 110,
                }}
              >
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                {progressLeft === 100 ? (
                  <Typography>
                    <span role="img" aria-label="tada">
                      🎉
                    </span>{' '}
                    Your tree is fully grown and will be sent off to be planted shortly.{' '}
                  </Typography>
                ) : (
                  <Typography>
                    Only <span style={{ color: 'secondary.main', fontWeight: 'bold' }}>{progressLeft}%</span> left until
                    your tree is fully grown!
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Snackbar>
    </>
  );
};
