import React, { FC, useContext } from 'react';
import createPersistedState from 'use-persisted-state';
import { logout } from '../lib/http/axios';
import { Source } from './config';
import { PodioContextType, User } from './models';
import { addTimesheet } from './timesheets/add-timesheet';
import { getProjects } from './timesheets/get-projects';
import { getTasks } from './timesheets/get-tasks';
import { getTimesheets } from './timesheets/get-timesheets';

const useUserState = createPersistedState<null | User>('tm-user');
const useSourceState = createPersistedState<null | Source>('tm-source');

const PodioContext = React.createContext<PodioContextType>(null as any); // eslint-disable-line @typescript-eslint/no-explicit-any

export function usePodio() {
  return useContext(PodioContext);
}

export const PodioProvider: FC = ({ children }) => {
  const [user, setUser] = useUserState(null);
  const [source, setSource] = useSourceState(null);

  const podio: PodioContextType = {
    user,
    setUser,
    source,
    setSource,
    addTimesheet: (timesheet) => {
      if (!user || !source) {
        logout();
        throw new Error('You are not logged in');
      }

      return addTimesheet(timesheet, user.id, source);
    },
    getTimesheets: (period) => {
      if (!user || !source) {
        logout();
        throw new Error('You are not logged in');
      }

      return getTimesheets(period, user.id, source);
    },
    getProjects: (query) => {
      if (!source) {
        logout();
        throw new Error('You are not logged in');
      }

      return getProjects(query, source);
    },
    getTasks: (query) => {
      if (!source) {
        logout();
        throw new Error('You are not logged in');
      }

      return getTasks(query, source);
    },
  };

  return <PodioContext.Provider value={podio}>{children}</PodioContext.Provider>;
};
