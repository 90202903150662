import { Timesheet } from '../../podio/models';
import { getISOWeek } from 'date-fns';

type WeeknumberIndexedTimesheets = Array<Timesheet[]>;

export function getIntervalInWeeks(timesheets: Timesheet[]): WeeknumberIndexedTimesheets {
  return timesheets.reduce((timesheetsInWeeks, timesheet) => {
    const weekNumber = getISOWeek(timesheet.date);

    if (!timesheetsInWeeks[weekNumber]) {
      timesheetsInWeeks[weekNumber] = [];
    }

    timesheetsInWeeks[weekNumber].push(timesheet);
    return timesheetsInWeeks;
  }, [] as WeeknumberIndexedTimesheets);
}
