export function getRefreshToken(): string {
  return localStorage.getItem('tm-refresh-token') ?? '';
}

export function setRefreshToken(newRefreshToken: string): void {
  localStorage.setItem('tm-refresh-token', newRefreshToken);
}

export function removeRefreshToken(): void {
  localStorage.removeItem('tm-refresh-token');
}
