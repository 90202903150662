import axios from 'axios';
import { logoutWithError } from '../../lib/http/axios';

export async function refreshAccessToken(refreshToken: string): Promise<{ accessToken: string; refreshToken: string }> {
  const response = await axios.get(`/api/auth/refresh-access-token?refreshToken=${refreshToken}`);

  if (response.status === 420) {
    logoutWithError(420);
    throw new Error('Unable to get a new access token because of rate limit');
  }

  return response.data;
}
