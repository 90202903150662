import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Panel } from '../core/Panel';
import { CircularGraph } from './graph-component';

type Props = {
  growth?: number;
};

// One could do some check on if the growth value matches an image
export const TreeCard: FC<Props> = ({ growth }) => {
  return (
    <Panel>
      <Grid container spacing={2} textAlign="center">
        <Grid item xs={12}>
          {growth !== undefined && (
            <img
              style={{ maxWidth: '300px', width: '100%' }}
              src={process.env.PUBLIC_URL + '/tree-images/tree' + growth / 5 + '.svg'}
            />
          )}
          {growth === undefined && <Box sx={{ Width: '300px', height: '300px' }}></Box>}
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Keep logging hours correctly <br /> to plant real trees in the world
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CircularGraph percentage={growth} />
        </Grid>
      </Grid>
    </Panel>
  );
};
