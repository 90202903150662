import { AxiosResponse } from 'axios';
import { Interval } from 'date-fns';
import { Source, sourceConfig } from '../config';
import { CachedResponse, podioCachedFetch } from '../core/cached-podio-fetch';
import { getFilterParams } from '../core/get-filter-params';
import { Item } from '../core/podio_api_types';
import { Timesheet, User } from '../models';
import { parseTimesheet } from './parse-timesheet';

export function getTimesheets(period: Interval, userId: User['id'], source: Source): CachedResponse<Timesheet[]> {
  const { timesheets, fieldIds } = sourceConfig[source];

  const filterParams = getFilterParams(period, userId, source);

  const mapper = async (response: AxiosResponse) => {
    const { items } = await response.data;

    return (items as Item[]).reverse().map((timesheet: Item) => parseTimesheet(timesheet, fieldIds));
  };

  return podioCachedFetch(`/item/app/${timesheets}/filter/`, mapper, {
    method: 'POST',
    data: JSON.stringify(filterParams),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
