import { Box, Typography } from '@mui/material';

interface ProjectProps {
  chips?: string[];
  particularities: string;
  planterLikes?: string;
}

export const AppSelectProjectDetails = ({ particularities }: ProjectProps) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography fontWeight="bold" mt={2} fontSize="16px">
        Project description
      </Typography>
      <Typography sx={{ textAlign: 'left', margin: 2, fontSize: '16px' }}>{particularities}</Typography>
    </Box>
  );
};
