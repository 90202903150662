import { alpha, Box, Container, styled, Theme, useMediaQuery } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountBox from '@mui/icons-material/AccountBox';
import { Link, useLocation } from 'react-router-dom';
import Leaf from 'mdi-material-ui/LeafCircle';
import { CalendarClock, CalendarMonth } from 'mdi-material-ui';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNavigationAction: {
      '&&': {
        padding: 0,
        minWidth: 0,
      },
      '& .MuiBottomNavigationAction-label': {
        fontSize: 14,
        color: alpha(theme.palette.text.primary, 0.6),
        '&.Mui-selected': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

const Nav = styled('nav')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  padding:
    '10px max(env(safe-area-inset-right), 10px) max(env(safe-area-inset-bottom), 10px) max(env(safe-area-inset-left), 10px)',
  boxShadow: '0px -1px 10px rgba(0, 0, 0, 0.07)',
}));

const iconSize = 'medium';

export function Menu() {
  const location = useLocation();
  const classes = useStyles();
  const isNarrowPhone = useMediaQuery('(min-width: 350px)');

  return (
    <Nav>
      <Container maxWidth="sm">
        <BottomNavigation showLabels value={location.pathname}>
          <BottomNavigationAction
            className={classes.bottomNavigationAction}
            label={isNarrowPhone ? 'Sheets' : ''}
            component={Link}
            to="/"
            value="/"
            icon={<CalendarClock fontSize={iconSize} />}
          />
          <BottomNavigationAction
            className={classes.bottomNavigationAction}
            label={isNarrowPhone ? 'Period' : ''}
            component={Link}
            to="/pay-period"
            value="/pay-period"
            icon={<CalendarMonth fontSize={iconSize} />}
          />
          <BottomNavigationAction
            className={classes.bottomNavigationAction}
            label={isNarrowPhone ? 'Garden' : ''}
            component={Link}
            to="/garden"
            value="/garden"
            sx={(theme) => ({
              color: alpha(theme.palette.garden, 0.6),
              '& span': {
                color: alpha(theme.palette.text.primary, 0.6),
              },
              transform: 'translateY(-16px)',
              '&.Mui-selected': {
                color: theme.palette.garden,
                '&& .MuiBottomNavigationAction-label': {
                  color: theme.palette.garden,
                },
              },
            })}
            icon={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  width: 54,
                  height: 54,
                  borderRadius: '50%',
                  marginBottom: '5px',
                  fontSize: 60,
                })}
              >
                <Leaf fontSize="inherit" />
              </Box>
            }
          />
          <BottomNavigationAction
            className={classes.bottomNavigationAction}
            label={isNarrowPhone ? 'Account' : ''}
            component={Link}
            to="/account"
            value="/account"
            icon={<AccountBox fontSize={iconSize} />}
          />
        </BottomNavigation>
      </Container>
    </Nav>
  );
}
