import { Box, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { Tree } from 'mdi-material-ui';

interface Props {
  title: string;
  amountPlanted: number;
}

export const ProjectTitle = ({ amountPlanted, title }: Props) => {
  return (
    <Grid
      container
      px={3}
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        my: 1,
        position: 'relative',
        '&::before': {
          content: "''",
          position: 'absolute',
          height: 2,
          backgroundColor: theme.palette.secondary.main,
          top: 'calc(50% - 1px)',
          left: 0,
          right: 0,
          zIndex: -1,
        },
      })}
    >
      <div style={{ flex: 1, display: 'flex', paddingRight: 10 }}>
        <Typography
          maxWidth="100%"
          px={1}
          sx={(theme) => ({
            display: 'inline-block',
            fontSize: 14,
            fontWeight: 300,
            backgroundColor: theme.palette.background.default,
            textAlign: 'left',
            lineBreak: 'anywhere',
          })}
        >
          {title}
        </Typography>
      </div>
      <Box px={1} display="flex" sx={(theme) => ({ backgroundColor: theme.palette.background.default })}>
        <Typography sx={{ color: 'garden', marginLeft: '5%', fontSize: 16 }}>{amountPlanted}</Typography>
        <Tree sx={{ color: 'garden', fontSize: 22 }} />
      </Box>
    </Grid>
  );
};
