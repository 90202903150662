import { Source, sourceConfig } from '../config';
import { search } from '../core/search';
import { Project, SearchType } from '../models';

export async function getProjects(query: string, source: Source): Promise<Project[]> {
  const results = await search(query, sourceConfig[source].project);

  return results.map((result: SearchType) => {
    return {
      projectTitle: result.title,
      projectId: result.id,
    };
  });
}
