import { Grid, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';
import UnderlinedTitle from '../core/underlined';
import { logout } from '../lib/http/axios';
import { Source, sourceConfig } from '../podio/config';
import { usePodio } from '../podio/use-podio';
import { NotficationsComponent } from './notifications/NotificationComponent';
import { useSettings } from './use-settings';

export function Account() {
  const { hourlyRate, workHours, darkMode, setHourlyRate, setWorkHours, setDarkMode } = useSettings();
  const { source } = usePodio();
  const currency = sourceConfig[source as Source].currency;

  function handleHourlyRateChange(event: ChangeEvent<HTMLInputElement>) {
    setHourlyRate(Number(event.target.value));
  }

  function handleWorkHoursChange(event: ChangeEvent<HTMLInputElement>) {
    setWorkHours(Number(event.target.value));
  }

  function handleDarkModeChange(event: ChangeEvent<HTMLInputElement>) {
    setDarkMode(event.target.checked);
  }

  return (
    <Grid container flexGrow={1} spacing={2} direction="column">
      <Grid item alignItems="center" textAlign="center" paddingBottom={2}>
        <UnderlinedTitle title={'Account'} />
      </Grid>
      <Grid item textAlign="center">
        <TextField
          label="Weekly work hours"
          variant="outlined"
          type="number"
          value={workHours || ''}
          onChange={handleWorkHoursChange}
          fullWidth
        />
      </Grid>
      <Grid item textAlign="center">
        <TextField
          label={'Hourly rate in ' + currency}
          variant="outlined"
          type="number"
          value={hourlyRate || ''}
          onChange={handleHourlyRateChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Typography>
          <Switch color="primary" checked={darkMode} onChange={handleDarkModeChange} name="darkMode" />
          Dark mode
        </Typography>
      </Grid>
      <Grid item alignContent="center">
        <NotficationsComponent />
      </Grid>
      <Grid item textAlign="center" marginTop="auto">
        <Button sx={{ width: '50%' }} onClick={logout} variant="outlined">
          Log Out
        </Button>
      </Grid>
    </Grid>
  );
}
