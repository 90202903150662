import { Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { addMonths, endOfMonth, format, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Navigation } from '../core/Navigation';
import UnderlinedTitle from '../core/underlined';
import { FaqButton } from '../core/faq-component';
import { getPayPeriodName } from '../lib/pay-period/get-pay-period-name';
import { getPayPeriodOfDate } from '../lib/pay-period/get-pay-period-of-date';
import { groupTimesheetsByDate } from '../lib/timesheets/group-timesheets-by-date';
import { Timesheet } from '../podio/models';
import { usePodio } from '../podio/use-podio';
import { PayPeriodStatistics } from './PayPeriodStatistics';
import { TaskViewer } from './TaskViewer';
import { TimesheetCard } from './TimesheetCard';

export function PayPeriod() {
  const { getTimesheets, source } = usePodio();
  const [payPeriod, setPayPeriod] = useState(
    getPayPeriodOfDate(new Date(), source) ?? { start: new Date(), end: new Date() },
  );
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [tab, setTab] = React.useState<number>(0);

  function previousPayPeriod() {
    if (source === 'Denmark') {
      setPayPeriod({
        start: subMonths(payPeriod.start, 1),
        end: subMonths(payPeriod.end, 1),
      });
    } else if (source === 'Norway') {
      setPayPeriod({
        start: subMonths(payPeriod.start, 1),
        end: endOfMonth(subMonths(payPeriod.start, 1)),
      });
    }
  }

  function nextPayPeriod() {
    if (source === 'Denmark') {
      setPayPeriod({
        start: addMonths(payPeriod.start, 1),
        end: addMonths(payPeriod.end, 1),
      });
    } else if (source === 'Norway') {
      setPayPeriod({
        start: addMonths(payPeriod.start, 1),
        end: endOfMonth(addMonths(payPeriod.start, 1)),
      });
    }
  }

  const name = getPayPeriodName(payPeriod);
  const timesheetsGroupedByDate = groupTimesheetsByDate(timesheets);

  useEffect(() => {
    const { cached, response } = getTimesheets(payPeriod);

    if (cached) {
      setTimesheets(cached);
    }

    response.then(setTimesheets);
  }, [getTimesheets, payPeriod]);

  return (
    <Box>
      <Box alignItems="center" textAlign="center" paddingBottom={2}>
        <UnderlinedTitle title="Pay Period" />
      </Box>
      <Navigation previous={previousPayPeriod} next={nextPayPeriod} title={name} />
      <PayPeriodStatistics payPeriod={payPeriod} timesheets={timesheets} />
      <Tabs
        value={tab}
        onChange={(e, tab) => setTab(tab)}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab label="Tasks" />
        <Tab label="Timesheets" />
      </Tabs>

      <FaqButton title="Estimate calculation">
        <Typography sx={{ textAlign: 'left', margin: 2 }}>
          The estimated days are the number of days in the pay period subtracting weekends and national holidays. Any
          other days off, such as your personal summer holidays, are not accounted for. For part time employees, the
          estimate is reduced accordingly. E.g. if you work 15 hours per week, a 20 day pay period would be reduced to
          15 / 37 * 20 ≈ 8 The earnings estimate is based on the estimated days, a 7.4 hour work day and your hourly
          rate.
        </Typography>
      </FaqButton>
      <Box mt={2}>
        {tab === 0 && <TaskViewer timesheets={timesheets} />}
        {tab === 1 &&
          timesheetsGroupedByDate.map((group) => (
            <div key={group.date}>
              <Typography variant="overline" gutterBottom={true}>
                {format(group.date, "do 'of' MMMM yyyy")}
              </Typography>

              {group.timesheets.map((timesheet) => (
                <Box mb={2} key={timesheet.id}>
                  <TimesheetCard timesheet={timesheet} detailsOfWork={timesheet.detailsOfWork} />
                </Box>
              ))}
            </div>
          ))}
      </Box>
    </Box>
  );
}
