import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Grid, Theme, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

type Props = {
  plantedTrees: number;
  co2Reduction: number;
};

export const CO2Reduction: FC<Props> = ({ plantedTrees, co2Reduction }) => {
  const theme = useTheme<Theme>();
  const styling: CSSProperties = { fontWeight: 'bold', color: theme.palette.secondary.main, fontSize: 24 };

  return (
    <Grid item textAlign="center">
      <Typography>
        You have planted
        <span style={styling}>&nbsp;{plantedTrees}&nbsp;</span>
        tree{plantedTrees == 1 ? '' : 's '} in total
      </Typography>
      <Typography>
        and saved
        <span style={styling}>&nbsp;{co2Reduction}</span>
        <span style={{ color: theme.palette.secondary.main }}>kg CO2</span>
      </Typography>
    </Grid>
  );
};
