import { Source, sourceConfig } from '../config';
import { search } from '../core/search';
import { Task, SearchType } from '../models';

export async function getTasks(query: string, source: Source): Promise<Task[]> {
  const results = await search(query, sourceConfig[source].task);

  return results.map((result: SearchType) => {
    return {
      taskTitle: result.title,
      taskId: result.id,
    } as Task; // To prevent type conflicts, has no effect on output
  });
}
