import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const SlideUpTransition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideLeftTransition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide direction="left" ref={ref} {...props} />;
});

const SlideRightTransition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide direction="right" ref={ref} {...props} />;
});

export { SlideUpTransition, SlideLeftTransition, SlideRightTransition };
