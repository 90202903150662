import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccessToken } from '../lib/auth/access-token';
import { setRefreshToken } from '../lib/auth/refresh-token';
import { getUser } from './auth/get-user';
import { Source } from './config';
import { usePodio } from './use-podio';

export function LoginCallback() {
  const { search } = useLocation();
  const { setSource, setUser } = usePodio();
  const navigate = useNavigate();

  const { accessToken, refreshToken, source } = useMemo(() => {
    const params = new URLSearchParams(search);

    return {
      accessToken: params.get('accessToken'),
      refreshToken: params.get('refreshToken'),
      source: params.get('source'),
    };
  }, [search]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const error = params.get('error');
    if (!!error && error.includes('420')) {
      navigate('/login?error=420', { replace: true });
    }

    if (!accessToken || !source) return;

    const fn = async () => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken ?? '');

      const user = await getUser();

      setSource(source as Source);
      setUser(user);

      if (!user) return;

      navigate('/', { replace: true });
    };

    fn();
  }, [accessToken, navigate, refreshToken, setSource, setUser, source, search]);

  return <></>;
}
