import { alpha } from '@material-ui/core';
import { Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import UnderlinedTitle from '../core/underlined';
import { apiUrl } from '../lib/http/axios';
import { Source } from './config';

interface SourceModel {
  name: string;
  url: string;
}

export function Login() {
  const theme = useTheme<Theme>();
  // Get query params
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const error = params.get('error');

  const sources: SourceModel[] = useMemo(
    () =>
      Object.entries(Source).map(([name, value]) => ({
        name,
        url: `${apiUrl}/api/auth/login?redirect_url=${window.location.origin}/login-callback?source=${value}`,
      })),
    [],
  );

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  const errorStyle: SxProps = {
    textAlign: 'center',
    fontSize: 12,
    mt: 3,
    color: alpha(theme.palette.text.primary, 0.7),
  };

  return (
    <Grid container flexDirection="row" alignItems="center" height="100%">
      <Grid p={4} container flexDirection="column" alignItems="center">
        <img
          src={process.env.PUBLIC_URL + '/logo.svg'}
          style={{
            width: '50vw',
            height: 'auto',
            maxWidth: 200,
            marginBottom: theme.spacing(5),
          }}
        />
        <UnderlinedTitle title="Time Mindster" fontSize={50} />
        {sources.map((source) => (
          <Button
            fullWidth
            href={source.url}
            key={source.url}
            variant="contained"
            sx={{
              mt: 4,
              maxWidth: 200,
              maxHeight: 50,
            }}
          >
            {source.name}
          </Button>
        ))}
        {!!error && error === '420' ? (
          <Typography sx={errorStyle}>
            We have unfortunately hit the rate limit for the current hour. Please try again later.
          </Typography>
        ) : (
          ''
        )}
        {isIOS ? (
          isPWA ? (
            <Typography sx={errorStyle}>
              If you have the Podio app installed, you will be redirected to the Podio app after login. To fix this
              issue please uninstall the Podio app before logging in, and resinstall it again afterwards.
            </Typography>
          ) : (
            <Typography sx={errorStyle}>
              If you have the Podio app installed, you will be redirected to the Podio app after login. To fix this
              issue please hold down the login button and choose open in Safari or another browser.
            </Typography>
          )
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
}
