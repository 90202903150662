export function getAccessToken(): string {
  return localStorage.getItem('tm-access-token') ?? '';
}

export function setAccessToken(newAccessToken: string): void {
  localStorage.setItem('tm-access-token', newAccessToken);
}

export function removeAccessToken(): void {
  localStorage.removeItem('tm-access-token');
}
