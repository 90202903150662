import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { FC } from 'react';

type Props = {
  open?: boolean | undefined;
  onClose: () => void;
  message?: string;
  messageSeverity: AlertColor;
};

export const TimeMindsterSnackbar: FC<Props> = ({ open, onClose, message, messageSeverity }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => onClose()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => onClose()} severity={messageSeverity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
