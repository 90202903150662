import { Grid, Typography } from '@mui/material';
import { DataCard } from '../core/data-card';
import { DataCardProps } from '../lib/models/data-card-model';

interface Props {
  data: DataCardProps[];
  allowCollapse?: boolean;
}

const CardList = ({ data, allowCollapse }: Props) => {
  return (
    <Grid container spacing={2} direction="column">
      {data.length === 0 ? (
        <Grid item textAlign="center">
          <Typography>No trees to show</Typography>
        </Grid>
      ) : (
        data.map((entity, index) => (
          <Grid item key={entity.id}>
            <DataCard {...entity} allowCollapse={allowCollapse} initialCollapse={allowCollapse && index !== 0} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default CardList;
