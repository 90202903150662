import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Paper, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface NavigationProps {
  previous: () => void;
  next: () => void;
  title: string;
}

export function Navigation({ previous, next, title }: NavigationProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        top: 'max(calc(env(safe-area-inset-top) + 10px), 10px)',
        zIndex: 2,
        mb: 1,
      }}
    >
      <Paper
        sx={{
          borderRadius: '18rem',
          maxWidth: '24rem',
          height: 'auto',
          maxHeight: '4rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <IconButton onClick={previous} aria-label="previous" size="large" sx={{ p: 0.5 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography component="span"> {title} </Typography>
        <IconButton onClick={next} aria-label="next" size="large" sx={{ p: 0.5 }}>
          <KeyboardArrowRight />
        </IconButton>
      </Paper>
    </Box>
  );
}
