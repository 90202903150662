import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFirebase } from './notifications/push-notifications';
import 'typeface-poppins';
import { setRequestInterceptors, setResponseInterceptors } from './lib/http/axios';

// Initialize Axios interceptors
setRequestInterceptors();
setResponseInterceptors();
initializeFirebase();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register({
  // Reload the page automatically when a new service worker is available
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        const serviceWorker = event.target as ServiceWorker;
        if (serviceWorker.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
