import { Grid, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
  percentage?: number;
};

export const CircularGraph = ({ percentage }: Props) => {
  const theme = useTheme<Theme>();

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container justifyContent="center">
      <Grid item paddingBottom={2} paddingTop={4} maxWidth="300px" width="100%">
        <CircularProgressbarWithChildren
          value={percentage ?? 0}
          strokeWidth={5}
          counterClockwise={true}
          styles={{
            path: {
              stroke: theme.palette.garden,
              strokeLinecap: 'unset',
            },
          }}
        >
          <Typography>
            <span style={{ fontSize: isDesktop ? 80 : '16vw', fontWeight: 'bold' }}>{percentage}</span>
            <span style={{ fontSize: isDesktop ? 40 : '8vw' }}>%</span>
          </Typography>
        </CircularProgressbarWithChildren>
      </Grid>
    </Grid>
  );
};
