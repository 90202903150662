import React, { Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import createPersistedState from 'use-persisted-state';

export interface SettingsContextType {
  hourlyRate: number;
  setHourlyRate: Dispatch<SetStateAction<number>>;

  workHours: number;
  setWorkHours: Dispatch<SetStateAction<number>>;

  receiptAccountId: string | null;
  setReceiptAccountId: Dispatch<SetStateAction<string | null>>;

  receiptDepartmentId: string | null;
  setReceiptDepartmentId: Dispatch<SetStateAction<string | null>>;

  darkMode: boolean;
  setDarkMode: Dispatch<SetStateAction<boolean>>;

  weekMode: boolean;
  setWeekMode: Dispatch<SetStateAction<boolean>>;
}

const SettingsContext = React.createContext<SettingsContextType>(null as any); // eslint-disable-line @typescript-eslint/no-explicit-any
const useHourlyRateState = createPersistedState<number>('tm-hourly-rate');
const useWorkHoursState = createPersistedState<number>('tm-work-hours');
const useReceiptAccountIdState = createPersistedState<string | null>('tm-receipt-account-id');
const useReceiptDepartmentIdState = createPersistedState<string | null>('tm-receipt-department-id');
const useDarkModeState = createPersistedState<boolean>('tm-dark-mode');
const useWeekModeState = createPersistedState<boolean>('tm-week-mode');

export function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }: { children: ReactNode }) {
  const [hourlyRate, setHourlyRate] = useHourlyRateState(0);
  const [workHours, setWorkHours] = useWorkHoursState(0);
  const [receiptAccountId, setReceiptAccountId] = useReceiptAccountIdState(null);
  const [receiptDepartmentId, setReceiptDepartmentId] = useReceiptDepartmentIdState(null);
  const [darkMode, setDarkMode] = useDarkModeState(false);
  const [weekMode, setWeekMode] = useWeekModeState(false);

  const store = {
    hourlyRate,
    setHourlyRate,
    workHours,
    setWorkHours,
    receiptAccountId,
    setReceiptAccountId,
    receiptDepartmentId,
    setReceiptDepartmentId,
    darkMode,
    setDarkMode,
    weekMode,
    setWeekMode,
  };

  return <SettingsContext.Provider value={store}>{children}</SettingsContext.Provider>;
}
