import { CardContent } from '@material-ui/core';
import { Theme } from '@mui/material';
import { CardMedia } from '@mui/material';
import { Card, Chip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { handleProjectImageError, handleSpeciesImageError } from '../lib/handleImageErrors';

interface Props {
  category: string;
  lifeTimeCO2Offset: number;
  project: string;
  species: string;
  projectImage: string;
  speciesImage: string;
}

export const UserPreferredTree = ({
  species,
  lifeTimeCO2Offset,
  project,
  category,
  projectImage,
  speciesImage,
}: Props) => {
  const theme = useTheme<Theme>();
  return (
    <Card sx={{ textAlign: 'left' }}>
      <CardContent>
        <dl>
          <Typography component="dt">Project</Typography>
          <Typography sx={{ fontWeight: 'bold' }} component="dd">
            {project}
          </Typography>
          <Typography component="dt">Species</Typography>
          <Typography sx={{ fontWeight: 'bold' }} component="dd">
            {species}
          </Typography>
        </dl>
        <Chip
          label={category}
          sx={{ backgroundColor: theme.palette.text.secondary, color: 'white', marginRight: 1, marginTop: 1 }}
        />
        <Chip
          label={lifeTimeCO2Offset.toString() + ' kg CO2'}
          sx={{ backgroundColor: theme.palette.text.secondary, color: 'white', marginTop: 1 }}
        />
      </CardContent>
      <CardMedia
        component="img"
        src={projectImage}
        onError={handleProjectImageError}
        sx={{ width: '50%', height: 'min(30vw, 200px)', display: 'inline-block', marginBottom: -1 }}
      />
      <CardMedia
        component="img"
        src={speciesImage}
        onError={handleSpeciesImageError}
        sx={{ width: '50%', height: 'min(30vw, 200px)', display: 'inline-block', marginBottom: -1 }}
      />
    </Card>
  );
};
