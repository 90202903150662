import { alpha, Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { addWeeks, getISOWeek, getYear, subWeeks } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '../account/use-settings';
import { DisplayDurationLongFormat } from '../core/DisplayDurationLongFormat';
import { appFetch } from '../core/hooks/use-fetch';
import { Navigation } from '../core/Navigation';
import UnderlinedTitle from '../core/underlined';
import { PopUpNotification } from './popup-component';
import { displayDuration } from '../lib/time/display-duration';
import { getRecentPeriod } from '../lib/time/get-recent-period';
import { getRemainingTimeLabel } from '../lib/time/get-remaining-time-label';
import { getWeekOfDate } from '../lib/time/get-week-of-date';
import { getMiddleOfDay } from '../lib/time/time-utils';
import { getDatesLogged } from '../lib/timesheets/get-dates-logged';
import { getRecentTasks } from '../lib/timesheets/get-recent-tasks';
import { getTimeSpentPerWeekday } from '../lib/timesheets/get-time-spent-per-weekday';
import { getTotalTimeSpent } from '../lib/timesheets/get-total-time-spent';
import { DateLogged, RecentTask, Timesheet } from '../podio/models';
import { usePodio } from '../podio/use-podio';
import { AddTimesheet } from './AddTimesheet';
import { Week } from './Week';

export function Timesheets() {
  const theme = useTheme<Theme>();

  const timeRemaningStyle: React.CSSProperties = {
    color: alpha(theme.palette.text.primary, 0.7),
    fontSize: '14px',
    fontStyle: 'italic',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const { getTimesheets, addTimesheet, user } = usePodio();
  const { workHours } = useSettings();

  const [week, setWeek] = useState(getWeekOfDate(new Date()));
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const [datesLogged, setDatesLogged] = useState<DateLogged[]>([]);

  const [recentPeriod, setRecentPeriod] = useState(getRecentPeriod(new Date()));
  const [recentTasks, setRecentTasks] = useState<RecentTask[]>([]);
  const [popupNotificationState, setPopupNotificationState] = useState<boolean>(false);
  const [treeProgressLeft, setTreeProgressLeft] = useState<number>(100);

  function previousWeek() {
    setWeek({
      start: subWeeks(week.start, 1),
      end: subWeeks(week.end, 1),
    });
  }

  function nextWeek() {
    setWeek({
      start: addWeeks(week.start, 1),
      end: addWeeks(week.end, 1),
    });
  }

  const weekName = `Week ${getISOWeek(week.start)} of ${getYear(week.start)}`;
  const timeSpentPerDay = useMemo(() => getTimeSpentPerWeekday(timesheets, datesLogged), [timesheets, datesLogged]);
  const totalTimeSpent = getTotalTimeSpent(timesheets);
  const remainingTime = Math.abs(workHours * 3600 - totalTimeSpent);
  const [hoursLeft, minutesLeft] = displayDuration(remainingTime);
  const remainingTimeLabel = getRemainingTimeLabel(totalTimeSpent, workHours);
  const [hours, minutes] = displayDuration(totalTimeSpent);

  const fetchAndSetDatesLogged = useCallback(
    async (week: Interval) => {
      if (user) {
        setDatesLogged(await getDatesLogged(week, user.id));
      }
    },
    [user],
  );

  useEffect(() => {
    fetchAndSetDatesLogged(week);
  }, [week, fetchAndSetDatesLogged]);

  useEffect(() => {
    const { cached, response } = getTimesheets(week);

    if (cached) {
      setTimesheets(cached);
    }

    response.then(setTimesheets);
  }, [getTimesheets, week]);

  useEffect(() => {
    const { cached, response } = getTimesheets(recentPeriod);

    if (cached) {
      setRecentTasks(getRecentTasks(cached));
    }

    response.then((timesheets) => getRecentTasks(timesheets)).then(setRecentTasks);
  }, [getTimesheets, recentPeriod]);

  const handleAddTimesheet = useCallback(
    async (timesheet: Timesheet) => {
      await addTimesheet(timesheet);

      // Trigger reload
      setWeek({ ...week });
      setRecentPeriod({ ...recentPeriod });
      if (!user) return;
      const response = await appFetch(`/api/garden/${user.id}/dateLogged`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ timeSheetDate: getMiddleOfDay(new Date(timesheet.date)), defaultWorkHours: workHours }),
      });

      if (response.status === 204) {
        return;
      }
      const { isLoggedCorrectly, treeProgress } = await response.data;

      setTreeProgressLeft(100 - treeProgress);
      setPopupNotificationState(isLoggedCorrectly);

      fetchAndSetDatesLogged({ ...week });
    },
    [addTimesheet, fetchAndSetDatesLogged, week, recentPeriod, user, workHours],
  );

  return (
    <Box>
      <PopUpNotification
        onClose={() => setPopupNotificationState(false)}
        progressLeft={treeProgressLeft}
        open={popupNotificationState}
        selfCloseTime={4000}
      />
      <Box alignItems="center" textAlign="center" paddingBottom={2}>
        <UnderlinedTitle title="Timesheets" />
      </Box>
      <Navigation previous={previousWeek} next={nextWeek} title={weekName} />
      <Week timeSpentPerDay={timeSpentPerDay} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt="0.5rem">
        <DisplayDurationLongFormat hours={hours} minutes={minutes} />
        <span>&nbsp;in total</span>
      </Box>
      {!!workHours && (
        <span style={timeRemaningStyle}>
          {' '}
          <DisplayDurationLongFormat
            hours={hoursLeft}
            minutes={minutesLeft}
            sxStyleNumber={timeRemaningStyle}
            sxStyleText={timeRemaningStyle}
          />{' '}
          &nbsp;{remainingTimeLabel}
        </span>
      )}
      <AddTimesheet recentTasks={recentTasks} onAdd={handleAddTimesheet} />
    </Box>
  );
}
