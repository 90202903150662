import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { appFetch } from '../core/hooks/use-fetch';
import UnderlinedTitle from '../core/underlined';
import { usePodio } from '../podio/use-podio';
import { CO2Reduction } from './CO2Reduction';
import { SelectTreeDialog } from './change-tree-dialog';
import { Streak } from '../core/streak';
import { TreeCard } from './tree-card';
import { Link } from 'react-router-dom';
import { UserPreferredTree } from './UserPreferredTree';
import { SpeciesStats } from '../forest/models';
import { ProjectTitle } from '../forest/ProjectTitle';
import { DataCard } from '../core/data-card';
import { AppSelectSpeciesDetails } from '../core/app-select-species-details';
import { handleSpeciesImageError } from '../lib/handleImageErrors';

export function Garden() {
  const { user } = usePodio();
  const [treesPlanted, setTreesPlanted] = useState(0);
  const [co2Reduction, setCo2Reduction] = useState(0);
  const [treeProgress, setTreeProgress] = useState(0);
  const [streaks, setStreaks] = useState({
    currentStreak: 0,
    longestStreak: 0,
  });
  const [preferredTree, setPreferredTree] = useState({
    speciesName: '',
    lifeTimeCO2: 0,
    category: { name: '' },
    speciesImage: '',
    projectImage: '',
    projectName: '',
  });
  const [speciesStats, setSpeciesStats] = useState<SpeciesStats[]>([]);
  const [fetchingGarden, setFetchingGarden] = useState<boolean>(true);

  const getGarden = useCallback(async () => {
    setFetchingGarden(true);
    const response = await appFetch(`/api/garden/${user?.id}`);
    const { amountPlanted, co2Reduction, treeProgress, currentStreak, longestStreak, speciesStats } = response.data;
    setTreesPlanted(amountPlanted);
    setCo2Reduction(co2Reduction);
    setTreeProgress(treeProgress);
    setStreaks({
      currentStreak: currentStreak,
      longestStreak: longestStreak,
    });
    setSpeciesStats(speciesStats);
    setFetchingGarden(false);
  }, [user?.id]);

  useEffect(() => {
    getGarden();
  }, [getGarden]);

  const getPreferredTree = useCallback(async () => {
    const response = await appFetch(`/api/garden/${user?.id}/preferredTree`);
    setPreferredTree(response.data);
  }, [user?.id]);

  useEffect(() => {
    if (fetchingGarden) return;
    getPreferredTree();
  }, [fetchingGarden, getPreferredTree]);

  return (
    <Grid container spacing={4} direction="column">
      <Grid item alignItems="center" textAlign="center">
        <UnderlinedTitle title="My Tree" />
      </Grid>
      <Grid item>
        <TreeCard growth={treeProgress} />
      </Grid>
      <Grid item>
        <Streak gardenStreaks={streaks} />
      </Grid>
      <Grid item>
        <CO2Reduction plantedTrees={treesPlanted} co2Reduction={co2Reduction} />
      </Grid>
      {speciesStats.length !== 0 && (
        <Grid item>
          <Typography textAlign="center" fontWeight={700}>
            {'Distributed on the following project'}
            {speciesStats.length > 1 ? 's' : ''}
          </Typography>
          {speciesStats.map((projects, index) => (
            <Grid item key={index}>
              <ProjectTitle
                title={`${projects.project.name}, ${projects.project.location}`}
                amountPlanted={projects.project.amountPlanted}
              />
              {projects.species.map((species) => (
                <DataCard
                  key={species.id}
                  allowCollapse={true}
                  initialCollapse={projects.species[projects.species.length - 1] === species}
                  image={species.image ?? ''}
                  title={species.name ?? ''}
                  amountPlanted={species.amountPlanted ?? 0}
                  id={species.treeNationId ?? 0}
                  handleImageError={handleSpeciesImageError}
                  description={
                    <AppSelectSpeciesDetails
                      particularities={species.description ?? ''}
                      planterLikes={species.planterLikes ?? ''}
                      chips={[
                        species.foliageType ?? '',
                        species.originType ?? '',
                        `${species.CO2Reduction}kg CO2`,
                        species.category ?? '',
                      ]}
                    />
                  }
                  chips={[species.category ?? '', `${species.CO2Reduction}kg CO2`]}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      )}
      <Grid item alignItems="center" textAlign="center">
        <Typography paddingBottom={2}>Which tree would you like to plant?</Typography>
        <UserPreferredTree
          species={preferredTree.speciesName}
          lifeTimeCO2Offset={preferredTree.lifeTimeCO2}
          category={preferredTree.category.name}
          project={preferredTree.projectName}
          projectImage={preferredTree.projectImage}
          speciesImage={preferredTree.speciesImage}
        />
      </Grid>
      <Grid item textAlign="center">
        <SelectTreeDialog getPreferredTree={getPreferredTree} />
      </Grid>
      <Grid item alignItems="center" textAlign="center">
        <Button variant="outlined" component={Link} to="/forest" sx={{ minWidth: '200px' }}>
          View ITM Forest
        </Button>
      </Grid>
    </Grid>
  );
}
