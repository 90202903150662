import { Grid, Typography } from '@mui/material';
import { Panel } from './Panel';
import { KpiComponent } from './KpiComponent';

interface Props {
  gardenStreaks?: {
    currentStreak: number;
    longestStreak: number;
  };
  forestStreaks?: {
    current: {
      average: number;
      longest: number;
    };
    longest: {
      average: number;
      longest: number;
    };
  };
}

export const Streak = ({ gardenStreaks, forestStreaks }: Props) => {
  return (
    <Panel>
      {gardenStreaks !== undefined && (
        <Grid container spacing={2} justifyContent="space-evenly">
          <KpiComponent
            number={gardenStreaks.currentStreak ?? 0}
            accentColor={true}
            text={'Current streak'}
            shortNotation={false}
            unit={' day'}
          />
          <KpiComponent
            number={gardenStreaks.longestStreak ?? 0}
            accentColor={false}
            text={'Longest streak'}
            shortNotation={false}
            unit={' day'}
          />
        </Grid>
      )}
      {forestStreaks !== undefined && (
        <Grid container direction="column">
          <Grid item>
            <Typography fontWeight={700} paddingBottom={0.5}>
              Current Streaks
            </Typography>
          </Grid>
          <Grid container>
            <Grid item width="50%">
              <KpiComponent
                number={forestStreaks.current.average ?? 0}
                accentColor={false}
                text={'Average'}
                shortNotation={false}
                unit={' day'}
              />
            </Grid>
            <Grid item width="50%">
              <KpiComponent
                number={forestStreaks.current.longest ?? 0}
                accentColor={true}
                text={'Longest'}
                shortNotation={false}
                unit={' day'}
              />
            </Grid>
          </Grid>
          <Grid item paddingTop={2} paddingBottom={0.5}>
            <Typography fontWeight={700}>All Time Streaks</Typography>
          </Grid>
          <Grid container>
            <Grid item width="50%">
              <KpiComponent
                number={forestStreaks.longest.average ?? 0}
                accentColor={false}
                text={'Highest average'}
                shortNotation={false}
                unit={' day'}
              />
            </Grid>
            <Grid item width="50%">
              <KpiComponent
                number={forestStreaks.longest.longest ?? 0}
                accentColor={true}
                text={'Longest streak'}
                shortNotation={false}
                unit={' day'}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
