import { Task, Timesheet } from '../../podio/models';
import { getRecentTasks } from './get-recent-tasks';

export interface TimeSpentOnTask extends Task {
  timeSpent: number;
}

export function getTimeSpentPerTask(timesheets: Timesheet[]): TimeSpentOnTask[] {
  const tasks = getRecentTasks(timesheets);

  const timeSpentPerTaskId = timesheets.reduce((timeSpentMap, { taskId, timeSpent }) => {
    const sum = timeSpentMap.get(taskId) || 0;
    return timeSpentMap.set(taskId, sum + timeSpent);
  }, new Map<Timesheet['taskId'], number>());

  return tasks.map((task) => {
    return {
      ...task,
      timeSpent: timeSpentPerTaskId.get(task.taskId) || 0,
    };
  });
}
