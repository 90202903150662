import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { pagePad } from '../core/AppThemeProvider';
import { appFetch } from '../core/hooks/use-fetch';
import { SlideLeftTransition } from '../lib/SlideTransitions';
import { handleProjectImageError, handleSpeciesImageError } from '../lib/handleImageErrors';
import { usePodio } from '../podio/use-podio';
import { AppSelectProjectDetails } from '../core/app-select-project-details';
import { AppSelectSpeciesDetails } from '../core/app-select-species-details';
import CardList from './card-list';
import { DataCardProps } from '../lib/models/data-card-model';

const steps = ['Select project', 'Select species'] as const;

type Step = typeof steps[number];

interface Props {
  getPreferredTree: () => void;
}

export const SelectTreeDialog = ({ getPreferredTree }: Props) => {
  const { user } = usePodio();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [step in Step]?: boolean }>({});
  const [chosenProjectId, setChosenProjectId] = useState<number>();
  const [chosenSpeciesId, setChosenSpeciesId] = useState<number>();
  const [projects, setProjects] = useState<DataCardProps[]>([]);
  const [speciesOnChosenProject, setSpeciesOnChosenProject] = useState<DataCardProps[]>([]);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(true);
  const [speciesLoading, setSpeciesLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const handleComplete = useCallback(() => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
  }, [activeStep, completed]);

  const handleSpeciesClick = useCallback(
    (id: number) => {
      setChosenSpeciesId(id);
      handleComplete();
    },
    [handleComplete],
  );

  const handleProjectClick = useCallback(
    (id: number) => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setChosenProjectId(id);
      handleComplete();
    },
    [handleComplete],
  );

  const handleReset = useCallback(() => {
    setOpen(false);
    setChosenProjectId(undefined);
    setChosenSpeciesId(undefined);
    setActiveStep(0);
    setCompleted({
      'Select project': false,
      'Select species': false,
    });
  }, []);

  const handleStep = (step: number) => () => {
    if (step === 0) setCompleted({});
    setActiveStep(step);
  };

  const getProjects = useCallback(async () => {
    setProjectsLoading(true);
    const respone = await appFetch(`/api/tree-nation/projects`);
    const treenationProjects: any[] = respone.data;
    setProjects(
      treenationProjects.map<DataCardProps>(({ id, name, description, location, image, url }) => ({
        id: id,
        title: `${name}, ${location}`,
        description: <AppSelectProjectDetails particularities={description} />,
        image: image,
        externalLink: url,
        handleMediaClick: () => handleProjectClick(id),
        handleImageError: (e) => handleProjectImageError(e),
      })),
    );
    setProjectsLoading(false);
  }, [handleProjectClick]);

  useEffect(() => {
    if (!open || !!projects.length) return;
    getProjects();
  }, [getProjects, open, projects]);

  const getSpeciesOnChosenProject = useCallback(async () => {
    setSpeciesLoading(true);
    const respone = await appFetch(`/api/tree-nation/projects/${chosenProjectId}/species`);
    const treenationSpeciesOnProject: any[] = respone.data;
    setSpeciesOnChosenProject(
      treenationSpeciesOnProject.map<DataCardProps>(
        ({ id, name, particularities, image, life_time_CO2, category, foliage_type, origin_type, planter_likes }) => ({
          id: id,
          title: name,
          description: (
            <AppSelectSpeciesDetails
              particularities={particularities}
              planterLikes={planter_likes}
              chips={[foliage_type.name, origin_type.name, `${life_time_CO2}kg CO2`, category.name]}
            />
          ),
          image: image,
          chips: [category.name, `${life_time_CO2}kg CO2`],
          handleMediaClick: () => handleSpeciesClick(id),
          handleImageError: (e) => handleSpeciesImageError(e),
        }),
      ),
    );
    setSpeciesLoading(false);
  }, [chosenProjectId, handleSpeciesClick]);

  useEffect(() => {
    if (chosenProjectId === undefined) return;
    getSpeciesOnChosenProject();
  }, [chosenProjectId, getSpeciesOnChosenProject]);

  const updateUsertreePreferences = useCallback(async () => {
    if (chosenSpeciesId === undefined) return;
    await appFetch(`/api/garden/${user?.id}/preferredTree?preferredTreeId=${chosenSpeciesId}`, { method: 'PATCH' });
    getPreferredTree();
    handleReset();
  }, [chosenSpeciesId, getPreferredTree, handleReset, user?.id]);

  useEffect(() => {
    if (chosenSpeciesId === undefined) return;
    updateUsertreePreferences();
  }, [chosenSpeciesId, updateUsertreePreferences]);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ minWidth: '200px' }}>
        Change tree
      </Button>
      <Dialog
        open={open}
        fullScreen
        TransitionComponent={SlideLeftTransition}
        onClose={() => setOpen(false)}
        style={{ zIndex: 5 }}
      >
        <Container disableGutters>
          <Grid
            container
            flexGrow={1}
            direction="column"
            paddingTop={4}
            spacing={2}
            paddingBottom={12}
            px={`${pagePad}px`}
          >
            <Grid item textAlign="center">
              <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton onClick={handleStep(index)} disabled={speciesLoading}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item textAlign="center">
              {activeStep === 0 ? (
                <>
                  {projectsLoading && <CircularProgress size={24} />}
                  {!projectsLoading && <CardList data={projects} />}
                </>
              ) : (
                <>
                  {speciesLoading && <CircularProgress size={24} />}
                  {!speciesLoading && <CardList data={speciesOnChosenProject} />}
                </>
              )}
            </Grid>
          </Grid>
          <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, borderRadius: 0 }}>
            <Box textAlign="center" p={2}>
              <Button
                onClick={() => {
                  handleReset();
                }}
                variant="outlined"
                sx={{ width: '40%' }}
              >
                Cancel
              </Button>
            </Box>
          </AppBar>
        </Container>
      </Dialog>
    </>
  );
};
