import { getDay, isSameDay, isValid, parseISO, toDate } from 'date-fns';
import { DateLogged, Timesheet } from '../../podio/models';
import { getMiddleOfDay } from '../time/time-utils';

type TimeSpentPerWeekDay = {
  name: string;
  timeSpent: number;
  loggedCorrectly: boolean;
};

export function getTimeSpentPerWeekday(
  timesheets: Timesheet[],
  datesLogged: DateLogged[],
): { name: string; timeSpent: number; loggedCorrectly: boolean }[] {
  return timesheets.reduce<TimeSpentPerWeekDay[]>(
    (week, { date, timeSpent }) => {
      // Get dayOfWeek where monday is 0 and sunday is 6
      const dayOfWeek = (getDay(date) + 6) % 7;
      const dateLogged = datesLogged.find((dateLogged) => {
        return isSameDay(getMiddleOfDay(parseISO(dateLogged.timeSheetDate)), getMiddleOfDay(toDate(date)));
      });

      week[dayOfWeek].loggedCorrectly =
        !!dateLogged &&
        isValid(parseISO(dateLogged?.timeSheetDate)) &&
        isSameDay(parseISO(dateLogged?.timeSheetDate), parseISO(dateLogged?.dateLogged));

      week[dayOfWeek].timeSpent += timeSpent;
      return week;
    },
    [
      { name: 'Mon', timeSpent: 0, loggedCorrectly: false },
      { name: 'Tue', timeSpent: 0, loggedCorrectly: false },
      { name: 'Wed', timeSpent: 0, loggedCorrectly: false },
      { name: 'Thu', timeSpent: 0, loggedCorrectly: false },
      { name: 'Fri', timeSpent: 0, loggedCorrectly: false },
      { name: 'Sat', timeSpent: 0, loggedCorrectly: false },
      { name: 'Sun', timeSpent: 0, loggedCorrectly: false },
    ],
  );
}
