import Add from '@mui/icons-material/Add';
import { Dialog, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { formatISO, getTime, parseISO } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { FormikFormSubmitter } from './FormikFormSubmitter';
import { useDebounce } from '../core/hooks/use-debounce';
import { SlideUpTransition } from '../lib/SlideTransitions';
import UnderlinedTitle from '../core/underlined';
import { Project, RecentTask, Task, Timesheet } from '../podio/models';
import { usePodio } from '../podio/use-podio';
import { RecentTaskCard } from './RecentTaskCard';
import { getMiddleOfDay } from '../lib/time/time-utils';

interface AddTimesheetFormValues {
  date: string;
  hours: string;
  minutes: string;
  details: string;
  project: null | Project;
  task: null | Task;
}

function getInitialFormValues(): AddTimesheetFormValues {
  return {
    date: formatISO(new Date(), { representation: 'date' }),
    hours: '',
    minutes: '',
    details: '',
    project: null,
    task: null,
  };
}

const validationSchema = Yup.object({
  details: Yup.string().required(),
  hours: Yup.number().min(0).max(23),
  minutes: Yup.number().min(0).max(59),
  date: Yup.date().required(),
  task: Yup.object().required(),
  project: Yup.object().required(),
}).test({
  test: ({ hours, minutes }) => {
    const isHoursValid = !!hours && hours > 0;
    const isMinutesValid = !!minutes && minutes > 0;

    if (isHoursValid || isMinutesValid) {
      return true;
    } else {
      return new ValidationError('Either hours or minutes must be set', 0, 'time');
    }
  },
});

export function AddTimesheet({
  recentTasks,
  onAdd,
}: {
  recentTasks: RecentTask[];
  onAdd: (timesheet: Timesheet) => Promise<void>;
}) {
  function hasError(fields: string[] | string, props: FormikProps<AddTimesheetFormValues>) {
    const array: string[] = Array.isArray(fields) ? fields : [fields];

    const touched = array.map((field) => props.getFieldMeta(field).touched).includes(true);

    const error = array.map((field) => !!props.getFieldMeta(field).error).includes(true);

    return touched && error;
  }

  const { getProjects, getTasks, user } = usePodio();

  const [detailsSuggestions, setDetailsSuggestions] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);

  const searchForTask = useCallback(
    (query: string) => {
      if (query.length > 2) {
        getTasks(query).then(setTasks);
      }
    },
    [getTasks, setTasks],
  );

  const taskSearch = useDebounce<string>('', 500, searchForTask);

  const searchForProject = useCallback(
    (query: string) => {
      if (query.length > 2) {
        getProjects(query).then(setProjects);
      }
    },
    [getProjects, setProjects],
  );

  const projectSearch = useDebounce<string>('', 500, searchForProject);

  const openDialog = useCallback(
    (props: FormikProps<AddTimesheetFormValues>, task: Task | null = null, suggestions: string[] = []) => {
      props.resetForm();
      props.setValues({
        ...getInitialFormValues(),
        task,
        project: task,
      });
      setDetailsSuggestions(suggestions);
      setOpen(true);
    },
    [],
  );

  const addRecentTask = useCallback(
    (task: RecentTask, props: FormikProps<AddTimesheetFormValues>) => {
      openDialog(props, task, task.detailsOfWorkSuggestions);
    },
    [openDialog],
  );

  function setAllFieldsTouched(touched: boolean, props: FormikProps<AddTimesheetFormValues>) {
    const allFields: { [T in keyof AddTimesheetFormValues]: boolean } = {
      date: touched,
      details: touched,
      hours: touched,
      minutes: touched,
      project: touched,
      task: touched,
    };

    props.setTouched(allFields);
  }

  function handleSubmit(props: FormikProps<AddTimesheetFormValues>) {
    setAllFieldsTouched(true, props);

    if (!props.isSubmitting && props.isValid) {
      props.submitForm();
      setOpen(false);
    }
  }

  const submitFormikForm = useCallback(
    async (values: AddTimesheetFormValues) => {
      const task = values.task as Task;
      const project = values.project as Project;
      if (!user) {
        return;
      }
      if (!user.id) {
        return;
      }
      await onAdd({
        taskId: task.taskId,
        taskTitle: task.taskTitle,
        projectId: project.projectId,
        projectTitle: project.projectTitle,
        timeSpent: (Number(values.hours) || 0) * 3600 + (Number(values.minutes) || 0) * 60,
        detailsOfWork: values.details,
        date: getTime(getMiddleOfDay(parseISO(values.date))),
      });
    },
    [onAdd, user],
  );
  return (
    <Formik<AddTimesheetFormValues>
      validationSchema={validationSchema}
      initialValues={getInitialFormValues()}
      onSubmit={submitFormikForm}
    >
      {(props) => {
        return (
          <>
            {recentTasks.map((task, index) => (
              <Box mt={2} key={task.taskId}>
                <RecentTaskCard task={task} onAdd={() => addRecentTask(task, props)} index={index} />
              </Box>
            ))}
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button startIcon={<Add />} onClick={() => openDialog(props)} variant="contained">
                Add timesheet
              </Button>
            </Box>
            <FormikFormSubmitter
              onSubmit={() => handleSubmit(props)}
              onAltNumberClick={(integerKey) => {
                const maxIndex = recentTasks.length;
                if (!isNaN(integerKey) && integerKey >= 1 && integerKey <= maxIndex) {
                  addRecentTask(recentTasks[integerKey - 1], props);
                }
              }}
            />
            <Dialog
              open={open}
              fullScreen
              TransitionComponent={SlideUpTransition}
              onClose={() => setOpen(false)}
              sx={{ marginTop: 6 }}
              PaperProps={{ style: { borderTopLeftRadius: 25, borderTopRightRadius: 25 } }}
            >
              <Box alignItems="center" textAlign="center" p={2}>
                <UnderlinedTitle title="Add timesheet" />
              </Box>
              <DialogContent>
                <Form>
                  <Autocomplete
                    options={projects}
                    isOptionEqualToValue={(option, value) => option.projectId === value.projectId}
                    getOptionLabel={(project: Project) => project.projectTitle}
                    onInputChange={(_, query) => projectSearch(query)}
                    renderInput={(params: unknown) => (
                      <TextField
                        {...params}
                        label="Project"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={hasError('project', props)}
                        autoFocus={!props.values.project}
                      />
                    )}
                    {...props.getFieldProps('project')}
                    onChange={(_, project) => props.setFieldValue('project', project)}
                  />
                  <Autocomplete
                    options={tasks}
                    isOptionEqualToValue={(option, value) => option.taskId === value.taskId}
                    getOptionLabel={(task: Task) => task.taskTitle}
                    onInputChange={(_, query) => taskSearch(query)}
                    renderInput={(params: unknown) => (
                      <TextField
                        {...params}
                        label="Task"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={hasError('task', props)}
                      />
                    )}
                    {...props.getFieldProps('task')}
                    onChange={(_, task) => props.setFieldValue('task', task)}
                  />

                  <TextField
                    fullWidth
                    label="Date"
                    variant="outlined"
                    type="date"
                    margin="normal"
                    error={hasError('date', props)}
                    {...props.getFieldProps('date')}
                  />

                  <Box display="flex">
                    <TextField
                      label="Hours"
                      variant="outlined"
                      type="number"
                      margin="normal"
                      error={hasError(['hours', 'time'], props)}
                      {...props.getFieldProps('hours')}
                      autoFocus={!!props.values.task}
                    />

                    <Box m={1} />

                    <TextField
                      label="Minutes"
                      variant="outlined"
                      type="number"
                      margin="normal"
                      error={hasError(['minutes', 'time'], props)}
                      {...props.getFieldProps('minutes')}
                    />
                  </Box>

                  <TextField
                    fullWidth
                    label="Details of work"
                    variant="outlined"
                    multiline
                    margin="normal"
                    error={hasError('details', props)}
                    {...props.getFieldProps('details')}
                  />

                  {detailsSuggestions.length > 0 && 'Suggestions:'}
                  {detailsSuggestions.slice(0, 5).map((suggestion, i) => (
                    <Box key={i} display="flex" alignItems="center" mt={1} pl={1}>
                      <Box flexGrow={1}>{suggestion}</Box>
                      <Button variant="outlined" onClick={() => props.setFieldValue('details', suggestion)}>
                        Use
                      </Button>
                    </Box>
                  ))}
                </Form>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={() => setOpen(false)} variant="outlined">
                  cancel
                </Button>
                <Button onClick={() => handleSubmit(props)} variant="contained" sx={{ minWidth: 150 }}>
                  add timesheet
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
}
