import { formatISO } from 'date-fns';
import { appFetch } from '../../core/hooks/use-fetch';
import { DateLogged } from '../../podio/models';

export async function getDatesLogged(interval: Interval, userId: number): Promise<DateLogged[]> {
  const baseUrl = '/api/garden';
  const rangeStartDate = encodeURIComponent(
    interval.start instanceof Date ? interval.start.toISOString() : formatISO(interval.start),
  );
  const rangeEndDate = encodeURIComponent(
    interval.end instanceof Date ? interval.end.toISOString() : formatISO(interval.end),
  );

  const response = await appFetch(
    `${baseUrl}/${userId}/dateLogged?rangeStartDate=${rangeStartDate}&rangeEndDate=${rangeEndDate}`,
  );

  return response.data;
}
