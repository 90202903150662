import { podioFetch } from '../core/podio-fetch';
import { User } from '../models';

export async function getUser(): Promise<User> {
  const response = await podioFetch('/user/status');

  const { user } = await response.data;

  return {
    id: user.user_id,
    mail: user.mail,
  };
}
