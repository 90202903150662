import { parseISO, setHours, startOfToday } from 'date-fns';

function parseISODate(date: string): Date {
  return getMiddleOfDay(parseISO(date));
}

// Parses date to midday to avoid timezone errors in current markets.
function getMiddleOfDay(date?: Date): Date {
  return setHours(date ?? startOfToday(), 12);
}

export { parseISODate, getMiddleOfDay };
