import { DesktopTimePicker, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AlertColor, Grid, Switch, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { getMinutes, isValid, startOfToday } from 'date-fns';
import daLocale from 'date-fns/locale/da';
import React, { useCallback, useEffect, useState } from 'react';
import createPersistedState from 'use-persisted-state';
import { appFetch } from '../../core/hooks/use-fetch';
import { usePodio } from '../../podio/use-podio';
import { askForPermissionToReceiveNotifications } from '../../notifications/push-notifications';
import { TimeMindsterSnackbar } from '../../Snackbar/TimeMindsterSnackbar';
import { getNotification } from './get-notification';

//This component contains all the logic for allowing Time Mindster to send out notifications

const useDeviceIdState = createPersistedState<string>('tm-device-id');
const useNotificationTimeState = createPersistedState<Date>('tm-notification-time');
const useNativeNotificationState = createPersistedState<boolean>('tm-native-notification-state');
const useSlackNotificationState = createPersistedState<boolean>('tm-slack-notification-state');
const useNotificationText = createPersistedState<string>('tm-notification-text');

export const NotficationsComponent = () => {
  const [deviceId, setDeviceId] = useDeviceIdState('');
  const [notificationTime, setNotificationTime] = useNotificationTimeState(new Date(Date.now()));
  const [nativeNotificationState, setNativeNotificationState] = useNativeNotificationState(false);
  const [slackNotificationState, setSlackNotificationState] = useSlackNotificationState(false);
  const [notificationText, setNotificationText] = useNotificationText('Remember to log your hours!');

  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const { user } = usePodio();

  const [loading, setLoading] = useState<boolean>(false);

  const init = useCallback(async () => {
    if (!user?.id) return;

    const notification = await getNotification(user.id);

    setNotificationText(notification.notificationText);

    setNotificationTime(convertToLocalDate(notification.notificationHours, notification.notificationMinutes));

    setSlackNotificationState(notification.recieveSlackNotification);

    // try to set native notification state
    try {
      const notificationDeviceKey = await askForPermissionToReceiveNotifications();
      setDeviceId(notificationDeviceKey);
      setNativeNotificationState(notification.devices.some((device) => device.deviceKey === notificationDeviceKey));
    } catch (error) {
      console.error('Could not get permission to send native notification!');
    }
    // setters triggers reload, we dont want that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  function showMessage(message: string, messageSeverity: AlertColor) {
    setSnackbarSeverity(messageSeverity);
    setSnackbarMessage(message);
    setOpen(true);
  }

  useEffect(() => {
    init();
  }, [init]);

  async function handleSaveClicked() {
    setLoading(true);
    if (!user?.id) return;
    try {
      appFetch('/api/notifications/settings', {
        method: 'POST',
        data: {
          id: user.id.toString(),
          recieveSlackNotification: slackNotificationState,
          notificationText,
          notificationHours: convertToUTCHoursAndMinutes(notificationTime).hours,
          notificationMinutes: convertToUTCHoursAndMinutes(notificationTime).minutes,
          deviceState: {
            deviceKey: deviceId,
            recieveNativeNotification: nativeNotificationState,
          },
        },
      });
      showMessage('Success!', 'success');
    } catch (error) {
      showMessage('Failed to save notification', 'error');
    }
    setLoading(false);
  }

  function handleTextPressed(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  function adjustDateQuarter() {
    const minutes = getMinutes(notificationTime);
    if (!notificationTime) {
      const today = startOfToday();
      today.setMinutes(15);
      today.setHours(18);
      setNotificationTime(today);

      showMessage('Notification time needs to be between 00:00 and 23:59!', 'info');
      return;
    }
    if (minutes % 15 != 0) {
      const roundedTime = notificationTime;

      roundedTime?.setMinutes(minutes - (minutes % 15));
      setNotificationTime(roundedTime);

      showMessage('minutes needs to be either 00, 15, 30 or 45. Time has been adjusted!', 'info');
    }
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <Typography>
          <Switch
            color="primary"
            checked={nativeNotificationState}
            onChange={(event) => setNativeNotificationState(event.target.checked)}
          />
          Native notifications (doesn't work for IOS)
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <Switch
            color="primary"
            checked={slackNotificationState}
            onChange={(event) => setSlackNotificationState(event.target.checked)}
          />
          Slack notifications
        </Typography>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
          {isDesktop ? (
            <DesktopTimePicker
              value={notificationTime}
              onChange={(date) => {
                setNotificationTime(isValid(date) && date ? date : new Date());
              }}
              disabled={!nativeNotificationState && !slackNotificationState}
              renderInput={(params) => <TextField fullWidth {...params} />}
              shouldDisableTime={(timeValue, clockType) => clockType === 'minutes' && timeValue % 15 != 0}
              InputProps={{
                onBlur: () => {
                  adjustDateQuarter();
                },
              }}
            />
          ) : (
            <MobileTimePicker
              value={notificationTime}
              onChange={(date) => {
                setNotificationTime(isValid(date) && date ? date : new Date());
              }}
              disabled={!nativeNotificationState && !slackNotificationState}
              renderInput={(params) => <TextField fullWidth {...params} />}
              shouldDisableTime={(timeValue, clockType) => clockType === 'minutes' && timeValue % 15 != 0}
              disableCloseOnSelect={false}
              onClose={() => {
                // Adjust time to quarters if not already
                const minutes = getMinutes(notificationTime);
                if (minutes % 15 != 0) {
                  const roundedTime = notificationTime;
                  if (!roundedTime) return;

                  roundedTime.setMinutes(minutes - (minutes % 15));
                  setNotificationTime(roundedTime);

                  showMessage('minutes needs to be either 00, 15, 30 or 45. Time has been adjusted!', 'info');
                }
              }}
            />
          )}
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          value={notificationText}
          onKeyPress={handleTextPressed}
          onChange={(event) => setNotificationText(event.target.value)}
          variant="outlined"
          multiline
          disabled={!nativeNotificationState && !slackNotificationState}
          inputProps={{ maxLength: 80 }}
        />
      </Grid>
      <Grid item textAlign="center">
        <Button sx={{ width: '50%' }} value="save" variant="contained" onClick={handleSaveClicked} disabled={loading}>
          Save
        </Button>
      </Grid>
      {
        <TimeMindsterSnackbar
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          message={snackbarMessage}
          messageSeverity={snackbarSeverity}
        />
      }
    </Grid>
  );
};

function convertToUTCHoursAndMinutes(date: Date) {
  return {
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
  };
}

function convertToLocalDate(hours: number, minutes: number) {
  const localNow = new Date();
  const timezoneDifference = -(localNow.getTimezoneOffset() / 60);
  return new Date(localNow.getFullYear(), localNow.getMonth(), localNow.getDate(), hours + timezoneDifference, minutes);
}
