import { alpha } from '@material-ui/core';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import {
  Components,
  createTheme,
  PaletteColorOptions,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import { GlobalStyles } from '@mui/styled-engine';
import React, { FunctionComponent, useMemo } from 'react';
import { useSettings } from '../account/use-settings';

declare module '@mui/material/styles' {
  interface Palette {
    garden: string;
  }
  interface PaletteOptions {
    garden: string;
  }
}

const primary: PaletteColorOptions = {
  main: '#0E83C6',
};

const secondary: PaletteColorOptions = {
  main: '#DD0C6A',
};

const garden = '#84BA24';

const typography: TypographyOptions = {
  fontFamily: ['Poppins', '-apple-system', 'sans-serif'].join(','),
  fontSize: 16,
  h1: {
    fontSize: 24,
    fontWeight: 600,
  },
};

const shadows = [
  'none',
  ...new Array(24).fill(1).map((_, index) => `${index + 1}px ${index + 1}px 20px rgba(0, 0, 0, 0.1)`),
] as Shadows;

const components: (theme: Theme) => Components<Theme> = (theme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        height: 45,
        borderRadius: 23,
        textTransform: 'none',
        borderColor: theme.palette.secondary.main,
      },
      contained: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        ':hover': {
          background: alpha(theme.palette.secondary.main, 0.7),
          boxShadow: 'none',
        },
      },
      outlined: {
        color: theme.palette.text.primary,
        ':hover': {
          background: alpha(theme.palette.secondary.main, 0.3),
          borderColor: 'transparent',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderColor: alpha(theme.palette.primary.main, 0.15),
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
      },
    },
  },
});

const lightTheme = () =>
  createTheme({
    shadows,
    palette: {
      mode: 'light',
      background: {
        default: '#FFF',
        paper: '#FFF',
      },
      text: {
        primary: '#222222',
      },
      garden,
      primary,
      secondary,
    },
    typography,
  });

const darkTheme = () =>
  createTheme({
    shadows,
    palette: {
      mode: 'dark',
      background: {
        default: '#212121',
        paper: '#333',
      },
      garden,
      primary,
      secondary,
    },
    typography,
  });

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        padding:
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        minHeight: '100vh',
        display: 'flex',
      },
      body: {
        display: 'flex',
      },
      'body, #root': {
        flex: 1,
      },
    }}
  />
);

export const pagePad = 30;

export const AppThemeProvider: FunctionComponent = ({ children }) => {
  const { darkMode } = useSettings();

  const theme = useMemo(() => {
    const theme = darkMode ? darkTheme() : lightTheme();

    theme.components = components(theme);

    return theme;
  }, [darkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {inputGlobalStyles}
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
