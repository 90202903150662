import { stringify } from 'querystring';
import { SearchType } from '../models';
import { podioFetch } from './podio-fetch';

export async function search(query: string, appId: number): Promise<SearchType[]> {
  const queryParams = {
    counts: true,
    highlights: false,
    limit: 10,
    offset: 0,
    ref_type: 'item',
    searchFields: 'title',
    query,
  };

  const response = await podioFetch(`/search/app/${appId}/v2?${stringify(queryParams)}`, {
    method: 'GET',
  });

  const { results } = await response.data;

  return results;
}
