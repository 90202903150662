import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { differenceInDays } from 'date-fns';
import { podioFetch } from './podio-fetch';

const daysBetweenCleanUps = 7;
const cleanUpStorageKey = 'tm-last-cache-cleanup';
const cacheStorageKeyPrefix = 'tm-request-';

export interface CachedResponse<T> {
  cached: T | null;
  response: Promise<T>;
}

export function podioCachedFetch<T>(
  path: string,
  mapper: (response: AxiosResponse) => Promise<T>,
  init?: AxiosRequestConfig,
): CachedResponse<T> {
  const key = getCacheKey(path, init);

  const storedValue = localStorage.getItem(key);
  const cached: T | null = storedValue && JSON.parse(storedValue);

  cleanUpCache();

  async function fetch() {
    const response = await podioFetch(path, init);

    const result = await mapper(response);

    localStorage.setItem(key, JSON.stringify(result));

    return result;
  }

  return {
    cached,
    response: fetch(),
  };
}

function cleanUpCache(): void {
  const lastCacheCleanup = localStorage.getItem(cleanUpStorageKey);

  if (!lastCacheCleanup) {
    localStorage.setItem(cleanUpStorageKey, new Date().getTime().toString());
    return;
  }

  if (differenceInDays(new Date(), Number(lastCacheCleanup)) >= daysBetweenCleanUps) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key === null) {
        break;
      }

      if (key && key.startsWith(cacheStorageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }

    localStorage.setItem(cleanUpStorageKey, new Date().getTime().toString());
  }
}

function getCacheKey(path: string, init?: AxiosRequestConfig) {
  const requestId = {
    path,
    body: init && init.data,
  };

  return cacheStorageKeyPrefix + JSON.stringify(requestId);
}
