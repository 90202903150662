import { Dialog, DialogContent, DialogTitle, Grid, Typography, Box } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { getMonth, getYear } from 'date-fns';
import React from 'react';
import { useSettings } from '../account/use-settings';
import { KpiComponent } from '../core/KpiComponent';
import { getTotalEarnings } from '../lib/earnings/get-total-earnings';
import { getWorkingDaysInSalaryTerm } from '../lib/national-holidays/get-working-days-in-salary-term';
import { getEstimatedEarnings } from '../lib/pay-period/get-estimated-earnings';
import { getEstimatedWorkingDays } from '../lib/pay-period/get-estimated-working-days';
import { displayDuration, displayDurationinDecimal } from '../lib/time/display-duration';
import { getTotalTimeSpent } from '../lib/timesheets/get-total-time-spent';
import { getWorkingDays } from '../lib/timesheets/get-working-days';
import { Source, sourceConfig } from '../podio/config';
import { Timesheet } from '../podio/models';
import { usePodio } from '../podio/use-podio';

interface PayPeriodStatisticsProps {
  timesheets: Timesheet[];
  payPeriod: Interval;
}

export function PayPeriodStatistics({ timesheets, payPeriod }: PayPeriodStatisticsProps) {
  const { hourlyRate, workHours } = useSettings();
  const { source } = usePodio();
  const [open, setOpen] = React.useState(false);

  const totalTimeSpent = getTotalTimeSpent(timesheets);
  const earnings = getTotalEarnings(totalTimeSpent, hourlyRate);
  const workingDays = getWorkingDays(timesheets);
  const averageTimePerDay = displayDurationinDecimal(totalTimeSpent / workingDays.length);
  const year = getYear(payPeriod.end);
  const month = getMonth(payPeriod.end);
  const holidays = sourceConfig[source as Source].getHolidays(year);
  const payedHolidays = sourceConfig[source as Source].getPayedHolidays(year);
  const workingDaysInSalaryTerm = getWorkingDaysInSalaryTerm(year, month, holidays, payedHolidays);
  const estimatedWorkingDays = getEstimatedWorkingDays(workingDaysInSalaryTerm.length, workHours);
  const estimatedHours = workingDaysInSalaryTerm.length * (workHours / 5);
  const estimatedMinutes = Math.round((estimatedHours % 1) * 60);
  const estimatedEarnings = getEstimatedEarnings(estimatedHours, hourlyRate);
  const [hours, minutes] = displayDuration(totalTimeSpent);

  return (
    <>
      <DisplayStatistics
        hours={hours}
        minutes={minutes}
        earnings={earnings}
        workedDays={workingDays.length}
        estimatedHours={Math.floor(estimatedHours)}
        estimatedMinutes={estimatedMinutes}
        estimatedEarnings={estimatedEarnings}
        estimatedWorkingDays={estimatedWorkingDays}
      />
      <AverageInformation
        averageTimePerDay={averageTimePerDay}
        workedDays={workingDays.length}
        estimatedWorkingDays={estimatedWorkingDays}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Estimate calculation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The estimated days are the number of days in the pay period subtracting weekends and national holidays. Any
            other days off, such as your personal summer holidays, are not accounted for.
            <br />
            <br />
            For part time employees, the estimate is reduced accordingly.
            <br />
            E.g. if you work 15 hours per week, a 20 day pay period would be reduced to 15 / 37 * 20 ≈ 8
            <br />
            <br />
            The earnings estimate is based on the estimated days, a 7.4 hour work day and your hourly rate.
            <br />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

interface PropsStatistics {
  hours: number;
  minutes: number;
  earnings: number;
  workedDays: number;
  estimatedHours: number;
  estimatedMinutes: number;
  estimatedEarnings: number;
  estimatedWorkingDays: number;
}

const DisplayStatistics = ({
  hours,
  minutes,
  earnings,
  workedDays,
  estimatedHours,
  estimatedMinutes,
  estimatedEarnings,
  estimatedWorkingDays,
}: PropsStatistics) => {
  return (
    <>
      <Grid pt={2} pb={2} container textAlign="center" alignItems="center" justifyContent="center">
        <Grid item px={1}>
          <KpiComponent
            number={Math.round((hours * 100) / 100)}
            secondaryNumber={Math.round((minutes * 100) / 100)}
            text={'Worked'}
            accentColor={true}
            shortNotation={true}
            unit={'h'}
            secondaryUnit={'m'}
          />
          <KpiComponent
            number={estimatedHours}
            secondaryNumber={estimatedMinutes}
            text={'Planned'}
            accentColor={false}
            shortNotation={true}
            unit={'h'}
            secondaryUnit={'m'}
          />
        </Grid>

        <Grid item px={1}>
          <KpiComponent
            number={Math.round(earnings).toLocaleString('it-IT', { maximumFractionDigits: 2 })}
            text={'Earned'}
            accentColor={true}
            shortNotation={true}
            unit={'kr'}
          />
          <KpiComponent
            number={Math.round(estimatedEarnings).toLocaleString('it-IT', { maximumFractionDigits: 2 })}
            text={'Estimated'}
            accentColor={false}
            shortNotation={true}
            unit={'kr'}
          />
        </Grid>
        <Grid item px={1}>
          <KpiComponent number={workedDays} text={'Worked'} accentColor={true} shortNotation={true} unit={'d'} />
          <KpiComponent
            number={estimatedWorkingDays.toLocaleString('it-IT')}
            text={'Planned'}
            accentColor={false}
            shortNotation={true}
            unit={'d'}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface PropsAverage {
  averageTimePerDay: number;
  estimatedWorkingDays: number;
  workedDays: number;
}
const AverageInformation = ({ averageTimePerDay, workedDays, estimatedWorkingDays }: PropsAverage) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Typography>You have worked an average of</Typography>
      {!isNaN(averageTimePerDay) ? (
        <Typography component="span" display="inline">
          {' '}
          <strong>{Math.round(averageTimePerDay * 10) / 10}</strong> hour
          {averageTimePerDay > 1 ? 's ' : ' '}
          per day over <strong>{workedDays}</strong> days out
        </Typography>
      ) : (
        <>
          <span>
            <strong>0 </strong>hours per day over <strong>{workedDays}</strong> days out
          </span>
        </>
      )}
      <Typography>
        {' '}
        of <strong>{estimatedWorkingDays}</strong> planned
      </Typography>
    </Box>
  );
};
