import { Grid, Typography } from '@mui/material';
import UnderlinedTitle from '../core/underlined';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyStats } from './CompanyStats';
import { appFetch } from '../core/hooks/use-fetch';
import startOfMonth from 'date-fns/esm/fp/startOfMonth/index.js';
import { differenceInCalendarMonths, endOfMonth, format, formatISO, parseISO, startOfToday } from 'date-fns';
import { getMiddleOfDay } from '../lib/time/time-utils';
import { ProjectTitle } from '../forest/ProjectTitle';
import { DataCard } from '../core/data-card';
import { Forest } from './models';
import { Streak } from '../core/streak';
import { AppSelectSpeciesDetails } from '../core/app-select-species-details';
import { handleSpeciesImageError } from '../lib/handleImageErrors';

export function ForestComponent() {
  const today = useMemo(() => getMiddleOfDay(), []);
  const periodEndDate = useMemo(() => endOfMonth(today), [today]);
  const periodStartDate = useMemo(() => startOfMonth(today), [today]);
  const [forest, setForest] = useState<Forest>({
    firstTreePlantedDate: formatISO(startOfToday()),
    forestSpeciesStats: [],
    amountOfTreesPlanted: 0,
    amountOfTreesPlantedinPeriod: 0,
    co2Total: 0,
    co2InPeriod: 0,
    streaks: { current: { average: 0, longest: 0 }, longest: { average: 0, longest: 0 } },
  });
  const [averageTreesPerMonth, setAverageTreesPerMonth] = useState<number>(0);

  const getForest = useCallback(async () => {
    const response = await appFetch(
      `/api/forest?periodStartDate=${encodeURIComponent(
        periodStartDate.toISOString(),
      )}&periodEndDate=${encodeURIComponent(periodEndDate.toISOString())}`,
    );
    setForest(response.data);
  }, [periodStartDate, periodEndDate]);

  useEffect(() => {
    getForest();
  }, [getForest]);

  useEffect(() => {
    const numberOfMonths = differenceInCalendarMonths(today, new Date(forest.firstTreePlantedDate)) + 1;
    const average = forest.amountOfTreesPlanted / numberOfMonths;
    setAverageTreesPerMonth(Number(average.toFixed(2)));
  }, [today, forest.firstTreePlantedDate, forest.amountOfTreesPlanted]);

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item alignItems="center" textAlign="center">
          <UnderlinedTitle title="ITM Forest" />
        </Grid>
        <Grid item alignItems="center" textAlign="center">
          <DisplayText
            treeCountOfAllTime={forest.amountOfTreesPlanted}
            averagePerMonthTrees={averageTreesPerMonth}
            date={forest.firstTreePlantedDate}
          />
        </Grid>
      </Grid>
      <CompanyStats
        co2OfAllTime={forest.co2Total / 1000}
        monthlyTrees={forest.amountOfTreesPlantedinPeriod}
        monthlyCo2={forest.co2InPeriod / 1000}
        treesOfAllTime={forest.amountOfTreesPlanted}
      />
      <Grid container direction="column" spacing={1} textAlign="center">
        {forest.forestSpeciesStats.length !== 0 && (
          <>
            <Typography fontWeight={700} mt={3} pl={'8px'}>
              {'Distributed on the following project'}
              {forest.forestSpeciesStats.length > 1 ? 's' : ''}
            </Typography>
            {forest.forestSpeciesStats.map((projects, projectIndex) => (
              <Grid item key={projectIndex}>
                <ProjectTitle
                  title={`${projects.project.name}, ${projects.project.location}`}
                  amountPlanted={projects.project.amountPlanted}
                />
                {projects.species.map((tree, treeIndex) => (
                  <DataCard
                    key={tree.id}
                    allowCollapse={true}
                    initialCollapse={projectIndex !== 0 || treeIndex !== 0}
                    image={tree.image ?? ''}
                    title={tree.name ?? ''}
                    amountPlanted={tree.amountPlanted ?? 0}
                    id={tree.treeNationId ?? 0}
                    handleImageError={handleSpeciesImageError}
                    description={
                      <AppSelectSpeciesDetails
                        particularities={tree.description ?? ''}
                        planterLikes={tree.planterLikes ?? ''}
                        chips={[
                          tree.foliageType ?? '',
                          tree.originType ?? '',
                          `${tree.CO2Reduction}kg CO2`,
                          tree.category ?? '',
                        ]}
                      />
                    }
                    chips={[tree.category ?? '', `${tree.CO2Reduction}kg CO2`]}
                  />
                ))}
              </Grid>
            ))}
          </>
        )}
        <Grid item textAlign="center">
          <Typography fontWeight={700} paddingTop={4} paddingBottom={1.5}>
            Company wide streaks
          </Typography>
          <Streak forestStreaks={forest.streaks} />
        </Grid>
      </Grid>
    </>
  );
}

interface Props {
  treeCountOfAllTime: number;
  averagePerMonthTrees: number;
  date: string;
}

const DisplayText = ({ treeCountOfAllTime, averagePerMonthTrees, date }: Props) => {
  return !(treeCountOfAllTime < 1) ? (
    <Typography fontSize={'min(18px, 4vw)'}>
      <span>
        <strong>IT Minds </strong>
        have planted <strong>{treeCountOfAllTime}</strong> tree{treeCountOfAllTime === 1 ? '' : 's '}
      </span>
      <br />
      since {format(parseISO(date), 'd MMM yyyy')} for an average of
      <br />
      <strong style={{ fontSize: 'min(18px, 4vw)' }}>
        {averagePerMonthTrees} tree{averagePerMonthTrees === 1 ? '' : 's '} per month
      </strong>
    </Typography>
  ) : (
    <Typography>No trees planted yet</Typography>
  );
};
