import { useCallback, useEffect } from 'react';

type Props = {
  onSubmit: () => void;
  onAltNumberClick: (integerKey: number) => void;
};

export const FormikFormSubmitter = ({ onSubmit, onAltNumberClick }: Props) => {
  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        onSubmit();
      }
      if (!e.altKey) return;
      onAltNumberClick(Number.parseInt(e.key));
    },
    [onAltNumberClick, onSubmit],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [keyHandler]);
  return <></>;
};
