import { getDanishHolidays } from '../lib/national-holidays/denmark/get-danish-holidays';
import { getDanishPayedHolidays } from '../lib/national-holidays/denmark/get-danish-payed-holidays';
import { getNorwegianHolidays } from '../lib/national-holidays/norway/get-norwegian-holidays';
import { getNorwegianPayedHolidays } from '../lib/national-holidays/norway/get-norwegian-payed-holidays';

export enum Source {
  Denmark = 'Denmark',
  Norway = 'Norway',
}

export interface PodioApps {
  project: number;
  task: number;
  timesheets: number;
}

export interface SourceConfig extends PodioApps {
  currency: string;
  getHolidays: (year: number) => Date[];
  getPayedHolidays: (year: number) => Date[];
  fieldIds: {
    project: number;
    timeSpent: number;
    date: number;
    task: number;
    detailsOfWork: number;
  };
}

export const podioApiOrigin = 'https://api.podio.com';

export const sourceConfig: { [x in Source]: SourceConfig } = {
  [Source.Denmark]: {
    currency: 'DKK',
    getHolidays: getDanishHolidays,
    getPayedHolidays: getDanishPayedHolidays,
    fieldIds: {
      date: 6984256,
      project: 6984254,
      task: 6984255,
      timeSpent: 6984257,
      detailsOfWork: 6984258,
    },
    project: 906856,
    task: 907417,
    timesheets: 956142,
  },
  [Source.Norway]: {
    currency: 'NOK',
    getHolidays: getNorwegianHolidays,
    getPayedHolidays: getNorwegianPayedHolidays,
    fieldIds: {
      date: 158514195,
      project: 158514193,
      task: 158514194,
      timeSpent: 158514196,
      detailsOfWork: 158514197,
    },
    project: 19680561,
    task: 19680563,
    timesheets: 19680564,
  },
};
