import React, { ChangeEvent } from 'react';
import { Switch, Typography } from '@mui/material';
import { useSettings } from '../account/use-settings';

export function WeekModeButton() {
  const { weekMode, setWeekMode } = useSettings();

  const handleWeekModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeekMode(event.target.checked);
  };

  return (
    <>
      <Typography component="div">
        <Switch color="primary" checked={weekMode} onChange={handleWeekModeChange} name="weekMode" />
        <span>Show per week in period</span>
      </Typography>
    </>
  );
}
