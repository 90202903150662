import { alpha, Container, Grid, Theme, Typography, useTheme } from '@mui/material';
import { pagePad } from '../core/AppThemeProvider';
import { KpiComponent } from '../core/KpiComponent';

export interface Props {
  treesOfAllTime: number;
  monthlyTrees: number;
  co2OfAllTime: number;
  monthlyCo2: number;
}

export const CompanyStats = ({ co2OfAllTime, monthlyCo2, monthlyTrees, treesOfAllTime }: Props) => {
  const theme = useTheme<Theme>();
  return (
    <Container disableGutters>
      <Grid
        container
        mt={2}
        pt={2}
        pb={2}
        ml={`-${pagePad}px`}
        mr={`-${pagePad}px`}
        px={`${pagePad}px`}
        direction="column"
        bgcolor={alpha(theme.palette.primary.main, 0.1)}
        textAlign="center"
        // container width is lg (1200px) as default, this calculation
        // sets the width to lg + the padding on the page
        sx={{ width: `min(calc(1200px + ${pagePad * 2}px), 100vw)` }}
      >
        <Grid item pb={1}>
          <Typography fontWeight="bold">We have planted</Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item width="50%">
            <KpiComponent
              accentColor={true}
              shortNotation={false}
              text={'this month'}
              number={monthlyTrees}
              unit={' tree'}
            />
          </Grid>
          <Grid item width="50%">
            <KpiComponent
              accentColor={true}
              shortNotation={false}
              text={'in total'}
              number={treesOfAllTime}
              unit={' tree'}
            />
          </Grid>
        </Grid>
        <Grid item pt={1} pb={1}>
          <Typography fontWeight="bold">And saved</Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item width="50%">
            <KpiComponent
              accentColor={true}
              shortNotation={true}
              text={'CO2 this month'}
              number={monthlyCo2.toFixed(1)}
              unit={'t'}
            />
          </Grid>
          <Grid item width="50%">
            <KpiComponent
              accentColor={true}
              shortNotation={true}
              text={'CO2 in total'}
              number={co2OfAllTime.toFixed(1)}
              unit={'t'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
