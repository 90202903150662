import { formatISO, Interval } from 'date-fns';
import { Source, sourceConfig } from '../config';
import { User } from '../models';

export function getFilterParams({ start, end }: Interval, userId: User['id'], source: Source) {
  return {
    filters: {
      created_by: {
        id: userId,
        type: 'user',
      },
      [sourceConfig[source].fieldIds.date]: {
        from: formatISO(start, { representation: 'date' }),
        to: formatISO(end, { representation: 'date' }),
      },
    },
    limit: 100,
    offset: 0,
    remember: false,
    sort_by: 'date',
    sort_desc: false,
  };
}
