import { Box, Fab } from '@mui/material';
import { useEffect } from 'react';
import useScript from './hooks/use-script';

const collecterConfig = {
  id: '94ca83be',
  trigger: 'issueCollector',
};

const BugEmoji = () => <span aria-label="bug">🐛</span>;

const AppIssueCollector = () => {
  useScript(
    `https://itminds.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=${collecterConfig.id}`,
    () => !!process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
  );

  useEffect(() => {
    (window as any).ATL_JQ_PAGE_PROPS = {
      [collecterConfig.id]: {
        triggerFunction: function (showCollectorDialog: () => void) {
          document.getElementById(collecterConfig.trigger)?.addEventListener('click', function (e) {
            e.preventDefault();
            showCollectorDialog();
          });
        },
      },
    };
  });

  return (
    <Box
      id={collecterConfig.trigger}
      sx={() => ({
        position: 'fixed',
        right: 10,
        top: 'calc(max(env(safe-area-inset-top), 0px) + 15px)',
        zIndex: 9001,
      })}
    >
      <Fab size="small" color="secondary" sx={(theme) => ({ boxShadow: theme.shadows[2] })}>
        <BugEmoji />
      </Fab>
    </Box>
  );
};

export default AppIssueCollector;
