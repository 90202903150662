import { RecentTask, Timesheet } from '../../podio/models';

export function getRecentTasks(timesheets: Timesheet[]): RecentTask[] {
  const taskMap = timesheets.reduce((tasks, timesheet) => {
    const recentTask = tasks.get(timesheet.taskId);
    if (recentTask) {
      recentTask.detailsOfWorkSuggestions.push(timesheet.detailsOfWork);
    } else {
      tasks.set(timesheet.taskId, {
        projectId: timesheet.projectId,
        projectTitle: timesheet.projectTitle,
        taskId: timesheet.taskId,
        taskTitle: timesheet.taskTitle,
        detailsOfWorkSuggestions: [timesheet.detailsOfWork],
      });
    }

    return tasks;
  }, new Map<Timesheet['taskId'], RecentTask>());

  const tasks = Array.from(taskMap.values());

  // Remove duplicate suggestions
  tasks.forEach((task) => (task.detailsOfWorkSuggestions = Array.from(new Set(task.detailsOfWorkSuggestions))));

  tasks.sort((a, b) => a.taskId - b.taskId);

  return tasks;
}
