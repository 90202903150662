import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

export interface Props {
  title: string;
  fontSize?: number;
}

const UnderlinedTitle = ({ title, fontSize = 24 }: Props) => {
  const underlineThickness = useMemo(() => fontSize * 0.2, [fontSize]);

  return (
    <Box
      sx={(theme) => ({
        '&::before': {
          backgroundColor: theme.palette.secondary.main,
          bottom: '-20%',
          content: "''",
          height: underlineThickness,
          position: 'absolute',
          width: '60%',
          left: '20%',
        },
        display: 'inline-block',
        position: 'relative',
        width: 'fit-content',
        marginBottom: fontSize * 0.1,
      })}
    >
      <Typography variant="h1" fontSize={fontSize} textAlign="center">
        {title}
      </Typography>
    </Box>
  );
};

export default UnderlinedTitle;
