import { Hidden, Paper, Theme, Typography, useTheme, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import React from 'react';
import { RecentTask } from '../podio/models';

export function RecentTaskCard({ task, onAdd, index }: { task: RecentTask; onAdd: () => void; index: number }) {
  const theme = useTheme<Theme>();
  return (
    <Paper>
      <Box p={2} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <span style={{ fontWeight: 'bold', lineBreak: 'anywhere' }}>{task.projectTitle && task.projectTitle}</span>
          <span style={{ color: alpha(theme.palette.text.primary, 0.7), lineBreak: 'anywhere' }}>
            {task.taskTitle && <div>{task.taskTitle}</div>}
          </span>
        </Box>
        <Box>
          <Hidden mdDown>
            <Typography variant="caption" component="span">
              Alt + {index + 1}
            </Typography>
          </Hidden>
          <IconButton aria-label="add timesheet" onClick={onAdd} sx={{ color: theme.palette.text.primary }}>
            <Add sx={{ fontSize: '2.5rem', fontWeight: 'bold' }} />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
