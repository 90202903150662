import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useDebounce<T>(
  initialValue: T,
  delay: number,
  callback: (value: T) => void,
): Dispatch<SetStateAction<T>> {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    const handler = setTimeout(() => callback(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay, callback]);

  return setValue;
}
