import { alpha, Box, Typography } from '@mui/material';
import { displayDuration } from '../lib/time/display-duration';
import Leaf from 'mdi-material-ui/Leaf';

const customFontSize = 'min(18px, 4.5vw)';

export function Week({
  timeSpentPerDay,
}: {
  timeSpentPerDay: { name: string; timeSpent: number; loggedCorrectly: boolean }[];
}) {
  return (
    <Box display="flex" textAlign="center" paddingBottom="0.5rem" pt="0.5rem">
      {timeSpentPerDay.map((day) => (
        <Box
          sx={(theme) => ({
            textAlign: 'center',
            borderRadius: '10px',
            margin: '0.1rem',
            ...(day.loggedCorrectly ? { backgroundColor: alpha(theme.palette.garden, 0.2) } : {}),
          })}
          key={day.name}
          flex="1 1 1px"
        >
          <Box
            sx={(theme) => ({
              transform: 'translateY(-8px)',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              margin: '0px auto',
              backgroundColor: theme.palette.background.default,
            })}
          >
            {day.loggedCorrectly ? (
              <Leaf
                sx={(theme) => ({
                  color: alpha(theme.palette.garden, 0.8),
                  padding: '1px',
                  marginLeft: '2px',
                  fontSize: `${customFontSize}`,
                })}
              />
            ) : (
              ''
            )}
          </Box>
          <Box key={day.name} flex="1 1 1px">
            <Typography
              sx={(theme) => ({
                fontSize: `${customFontSize}`,
                color: alpha(theme.palette.text.primary, 0.7),
                fontWeight: 300,
              })}
            >
              {day.name}
            </Typography>
            <Typography sx={{ fontSize: `${customFontSize}` }}>
              {day.timeSpent ? displayDuration(day.timeSpent)[0] + 'h' : ''}
            </Typography>
            <Typography sx={{ fontSize: `${customFontSize}` }}>
              {day.timeSpent ? displayDuration(day.timeSpent)[1] + 'm' : ''}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
