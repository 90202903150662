import { makeStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, IconButton, SwipeableDrawer, useTheme } from '@mui/material';
import React, { FC, useState } from 'react';
import { pagePad } from './AppThemeProvider';
import UnderlinedTitle from './underlined';

type Props = {
  title: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const FaqButton: FC<Props> = ({ title, style, children }: Props) => {
  const useStyles = makeStyles({
    paper: {
      width: '100%',
    },
  });

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles();

  function toggleDrawer() {
    !drawerState ? setDrawerState(true) : setDrawerState(false);
  }

  return (
    <>
      <Button
        onClick={toggleDrawer}
        style={{
          position: 'absolute',
          right: '0',
          minWidth: '4vw',
          borderBottomLeftRadius: 100,
          borderTopLeftRadius: 100,
          borderWidth: 5,
          backgroundColor: theme.palette.secondary.main,
          ...style,
        }}
      >
        <HelpOutline sx={{ color: 'white', margin: 'auto', fontSize: 35 }} />
      </Button>
      <SwipeableDrawer
        classes={{ paper: classes.paper }}
        open={drawerState}
        anchor="right"
        onClose={() => {
          setDrawerState(false);
        }}
        onOpen={() => {
          setDrawerState(true);
        }}
      >
        <Container disableGutters>
          <Box
            pt={'max(env(safe-area-inset-top), 20px)'}
            pb={'max(env(safe-area-inset-bottom), 20px )'}
            sx={{ textAlign: 'center', position: 'relative' }}
            px={`${pagePad}px`}
          >
            <IconButton sx={{ position: 'absolute', top: 'calc(50% -23.5px)', left: '5%' }} onClick={toggleDrawer}>
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
            <Box sx={{ maxWidth: '65%', marginLeft: 'auto', marginRight: 'auto' }}>
              <UnderlinedTitle title={title}></UnderlinedTitle>
            </Box>
            {children}
          </Box>
        </Container>
      </SwipeableDrawer>
    </>
  );
};
