import axios, { AxiosRequestConfig } from 'axios';

export const appFetch = async (url: string, options?: AxiosRequestConfig) => {
  try {
    const response = await axios(url, options);

    if (response.status >= 400) {
      throw new Error('Something unexpected has happended. Try again later');
    }
    return response;
  } catch (err) {
    throw err;
  }
};
