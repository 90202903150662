import { addDays, differenceInDays, isEqual, isWeekend, subMonths } from 'date-fns';

export function getWorkingDaysInSalaryTerm(year: number, month: number, holidays: Date[], payedHolidays: Date[]) {
  const startDay = subMonths(new Date(year, month, 16), 1);
  const endDay = new Date(year, month, 15);

  const numberOfDays = differenceInDays(endDay, startDay) + 1;

  const days = Array.from(new Array(numberOfDays)).map((_, index) => addDays(startDay, index));

  const weekDays = days.filter((day) => !isWeekend(day));

  const workingDays = weekDays.filter((weekday) => {
    if (payedHolidays.find((holiday) => isEqual(holiday, weekday))) {
      return true;
    }

    if (holidays.find((holiday) => isEqual(holiday, weekday))) {
      return false;
    }

    return true;
  });

  return workingDays;
}
