import { AlertColor, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { Account } from '../account/Account';
import { ForestComponent } from '../forest/Forest';
import { Garden } from '../garden/Garden';
import { PayPeriod } from '../pay-period/PayPeriod';
import { usePodio } from '../podio/use-podio';
import { TimeMindsterSnackbar } from '../Snackbar/TimeMindsterSnackbar';
import { Timesheets } from '../timesheets/Timesheets';
import { pagePad } from './AppThemeProvider';
import { appFetch } from './hooks/use-fetch';
import { Menu } from './Menu';

const useStyles = makeStyles({
  main: {
    padding: `${pagePad}px ${pagePad}px 100px`,
    minHeight: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

export function Shell() {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean | undefined>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info');
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const { user } = usePodio();

  useEffect(() => {
    async function checkStock() {
      const stockResponse = await appFetch(`/api/garden/${user?.id}/preferredTreeStock`);

      if (stockResponse.status === 204) return;

      if (stockResponse.data.stock <= 100 && stockResponse.data.stock != 0) {
        setSnackbarSeverity('warning');
        setSnackbarMessage('Your preferred tree has a low stock.');
        setSnackbarOpen(true);
      }

      if (stockResponse.data.stock <= 0) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Your preferred tree has no stock left. Please select another tree.');
        setSnackbarOpen(true);
      }
    }

    checkStock();
  }, [user?.id]);

  return (
    <>
      <main className={classes.main}>
        <Container disableGutters>
          <Routes>
            <Route path="/account" element={<Account />} />
            <Route path="/pay-period" element={<PayPeriod />} />
            <Route path="/garden" element={<Garden />} />
            <Route path="/" element={<Timesheets />} />
            <Route path="/forest" element={<ForestComponent />} />
          </Routes>
        </Container>
      </main>
      <Menu />
      <TimeMindsterSnackbar
        onClose={() => {
          setSnackbarOpen(false);
        }}
        open={snackbarOpen}
        message={snackbarMessage}
        messageSeverity={snackbarSeverity}
      />
    </>
  );
}
