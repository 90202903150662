import { getTotalTimeSpent } from '../lib/timesheets/get-total-time-spent';
import { FC } from 'react';
import { Timesheet } from '../podio/models';
import { displayDuration } from '../lib/time/display-duration';
import Typography from '@mui/material/Typography';
import { getTimeSpentPerTask } from '../lib/timesheets/get-time-spent-per-task';
import { getISOWeek } from 'date-fns';
import { TimesheetCard } from './TimesheetCard';
import { DisplayDurationLongFormat } from '../core/DisplayDurationLongFormat';

type WeekCardProps = {
  timesheets: Timesheet[];
};

function getWeekNumber(timesheets: Timesheet[]) {
  const [arbitraryDateInWeek] = timesheets;
  return getISOWeek(arbitraryDateInWeek.date);
}

export const WeekCards: FC<WeekCardProps> = ({ timesheets }) => {
  const weekNumber = getWeekNumber(timesheets);
  const timeSpentOnWeek = getTotalTimeSpent(timesheets);
  const taskSummarised = getTimeSpentPerTask(timesheets);
  const [hours, minutes] = displayDuration(timeSpentOnWeek);
  return (
    <>
      <Typography variant="overline" display="block" gutterBottom>
        WEEK {weekNumber} | <DisplayDurationLongFormat hours={hours} minutes={minutes} />
      </Typography>

      {taskSummarised.map((task) => (
        <TimesheetCard timesheet={task} key={task.taskId} />
      ))}
    </>
  );
};
