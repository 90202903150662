import { addDays, addWeeks, differenceInDays, subDays } from 'date-fns';
import { getEasterSunday } from '../get-easter-sunday';

export function getDanishHolidays(year: number) {
  const newYearsDay = new Date(year, 0, 1);
  const christmasDay = new Date(year - 1, 11, 25);
  const boxingDay = new Date(year - 1, 11, 26);

  const easterSunday = getEasterSunday(year);
  const easterMonday = addDays(easterSunday, 1);
  const maundyThursday = subDays(easterSunday, 3);
  const goodFriday = subDays(easterSunday, 2);
  const greatPrayerDay = addWeeks(addDays(easterSunday, 5), 3);
  const ascensionDay = addWeeks(addDays(easterSunday, 4), 5);
  const whitSunday = addWeeks(easterSunday, 7);
  const whitMonday = addWeeks(addDays(easterSunday, 1), 7);

  const holidays = [
    newYearsDay,
    christmasDay,
    boxingDay,
    maundyThursday,
    goodFriday,
    easterSunday,
    easterMonday,
    greatPrayerDay,
    ascensionDay,
    whitSunday,
    whitMonday,
  ];

  return holidays.sort((a, b) => differenceInDays(a, b));
}
