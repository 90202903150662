import { SxProps, Typography } from '@mui/material';

export interface Props {
  hours: number;
  minutes: number;
  sxStyleNumber?: SxProps;
  sxStyleText?: SxProps;
}

export const DisplayDurationLongFormat = ({ hours, minutes, sxStyleNumber, sxStyleText }: Props) => {
  return (
    <>
      <DisplayText time={hours} unit="hour" sxStyleNumber={sxStyleNumber} sxStyleText={sxStyleText} />
      {!!minutes && (
        <>
          &nbsp;
          <DisplayText time={minutes} unit="minute" sxStyleNumber={sxStyleNumber} sxStyleText={sxStyleText} />{' '}
        </>
      )}
    </>
  );
};

type PropsText = {
  time: number;
  unit: string;
  sxStyleNumber?: SxProps;
  sxStyleText?: SxProps;
};

const DisplayText = ({ time, unit, sxStyleNumber, sxStyleText }: PropsText) => {
  return (
    <>
      <Typography display="inline" sx={{ fontWeight: 'bold', color: 'secondary.main', ...sxStyleNumber }}>
        {time}&nbsp;
      </Typography>
      <Typography display="inline" sx={sxStyleText}>
        {unit}
        {time == 1 ? '' : 's'}
      </Typography>
    </>
  );
};
