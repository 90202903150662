import { CssBaseline } from '@mui/material';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SettingsProvider } from './account/use-settings';
import { AppThemeProvider } from './core/AppThemeProvider';
import AppIssueCollector from './core/Issue-collector';
import ScrollToTop from './core/scroll-to-top';
import { Shell } from './core/Shell';
import { Login } from './podio/Login';
import { LoginCallback } from './podio/LoginCallback';
import { PodioProvider } from './podio/use-podio';

export function App() {
  return (
    <SettingsProvider>
      <PodioProvider>
        <AppThemeProvider>
          <CssBaseline />
          <AppIssueCollector />
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login-callback" element={<LoginCallback />} />
              <Route path="*" element={<Shell />} />
            </Routes>
          </Router>
        </AppThemeProvider>
      </PodioProvider>
    </SettingsProvider>
  );
}
