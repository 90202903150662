import { Chip, Grid, Theme, Typography, useTheme } from '@mui/material';

interface ProjectProps {
  chips?: string[];
  particularities?: string;
  planterLikes?: string;
}

export const AppSelectSpeciesDetails = ({ particularities, chips, planterLikes }: ProjectProps) => {
  const theme = useTheme<Theme>();
  return (
    <>
      <Grid container mt={1} direction="row" justifyContent="center" textAlign="center">
        {chips?.map((chip, index) => (
          <Grid
            key={index}
            item
            style={{
              marginRight: 5,
              marginTop: 5,
            }}
          >
            <Chip
              key={`${chip}_${index}`}
              sx={{
                minWidth: '155px',
                backgroundColor: theme.palette.text.secondary,
                color: 'white',
                bottom: 12 + 43 * index,
              }}
              label={chip}
            />
          </Grid>
        ))}
      </Grid>
      {planterLikes != null ? (
        <>
          <Typography fontWeight="bold" mt={4} fontSize="16px" textAlign="center">
            What the planters like about it
          </Typography>
          <Typography sx={{ textAlign: 'left', margin: 2, fontSize: '16px' }}>{planterLikes}</Typography>
        </>
      ) : (
        ''
      )}

      {particularities != null ? (
        <>
          <Typography fontWeight="bold" mt={2} fontSize="16px" textAlign="center">
            Species particularities
          </Typography>
          <Typography sx={{ textAlign: 'left', margin: 2, fontSize: '16px' }}>{particularities}</Typography>
        </>
      ) : (
        ''
      )}
    </>
  );
};
