import { getTime, parseISO } from 'date-fns';
import { SourceConfig } from '../config';
import { Item, ValueObject } from '../core/podio_api_types';
import { Timesheet } from '../models';

// Timesheets in podio saves details of work as html, and we need to strip those tags before posting/using it.
// it handles newlines a bit weird. If you enter a single newline it inserts a <br /> tag, i.e.
// "Line one
// Line two"
// becomes <p>Line one<br />Line two</p>
// If however there's am empty line in between it splits it into different pragraphs, i.e
// "Line one
//
// Line two"
// becomes <p>Line one</p><p>Line two</p>
function removeHTML(value: string): string {
  // Using the DOMParser to remove the html effectively just removes the <br /> tags without inserting a newline.
  // It doesn't handle pargraphs that elegant either
  // because of this we replace the <br /> tag with a newline, then appends two newlines to any parapgraph end tags to ensure
  // the empty line is inserted again when chosing a suggestion
  const newlineFix = value.replaceAll('<br/>', '\n').replaceAll('</p>', '</p>\n\n').trim();
  // Parse the text to a document
  const doc = new DOMParser().parseFromString(newlineFix, 'text/html');

  // Extract the text from the document as plain text
  return doc.body.textContent || doc.body.innerText || '';
}

export function parseTimesheet(timesheet: Item, fieldIds: SourceConfig['fieldIds']): Timesheet {
  function extractField(fieldId: number, property: 'value' | 'start_date' = 'value') {
    const field = timesheet.fields.find((i: { field_id: number }) => i.field_id === fieldId);

    if (!field) {
      return;
    }

    return field.values[0][property];
  }

  const project = extractField(fieldIds.project) as ValueObject;
  const task = extractField(fieldIds.task) as ValueObject;
  const startDate = extractField(fieldIds.date, 'start_date') as string;
  const detailsOfWork = extractField(fieldIds.detailsOfWork) as string;
  const timeSpent = extractField(fieldIds.timeSpent) as number;

  return {
    id: timesheet.app_item_id,
    projectTitle: project.title as string,
    projectId: project.item_id as number,
    taskTitle: task.title as string,
    taskId: task.item_id as number,
    timeSpent: timeSpent,
    date: getTime(parseISO(startDate)),
    detailsOfWork: removeHTML(detailsOfWork || ''),
  };
}
