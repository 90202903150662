// TODO change image to actual images when ready
const handleSpeciesImageError = (e) => {
  e.target.onerror = '';
  e.target.src = `/error-images/missingTreeImage.svg`;
};

const handleProjectImageError = (e) => {
  e.target.onerror = '';
  e.target.src = `/error-images/missingProjectImage.svg`;
};

export { handleSpeciesImageError, handleProjectImageError };
