import { Grid, Paper, SxProps } from '@mui/material';
import { FC } from 'react';
interface Props {
  sxStyle?: SxProps;
  children: React.ReactNode;
}

export const Panel: FC<Props> = ({ children, sxStyle }) => {
  return (
    <Paper>
      <Grid p={2} sx={{ ...sxStyle }}>
        {children}
      </Grid>
    </Paper>
  );
};
