import { Timesheet } from '../../podio/models';

export function groupTimesheetsByDate(timesheets: Timesheet[]) {
  const map = timesheets.reduce<Map<number, Timesheet[]>>((map, timesheet) => {
    const list = map.get(timesheet.date);
    if (list) {
      list.push(timesheet);
    } else {
      map.set(timesheet.date, [timesheet]);
    }
    return map;
  }, new Map<number, Timesheet[]>());

  return Array.from(map.entries())
    .map(([date, timesheets]) => ({ date, timesheets }))
    .sort((a, b) => b.date - a.date);
}
